import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  CREATE_SPRINT,
  UPDATE_SPRINT,
  GET_ALL_SPRINTS,
} from "../../Graphql/User/Query";
import { v4 as uuidv4 } from "uuid";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import { differenceInDays, parseISO } from "date-fns";
import { ReactComponent as Edit } from "../../Assets/Svgs/edit.svg";
import { ReactComponent as Delete } from "../../Assets/Svgs/deletetask.svg";

const variableTypeDescriptions = {
  Dichotomous:
    "Dichotomous variables are binary (e.g., Yes/No, True/False). In this configuration, the baseline is fixed at 0. Please enter the absolute number of successful days (Goal) and the minimum days for termination.",
  "Likert Scale":
    "A Categorical variable such as a Likert scale is used to measure attitudes or responses on a fixed scale. In this configuration, the baseline is fixed at 0. Please enter the absolute number of successful days (Goal) and the minimum days for termination.",
  Continuous:
    "Continuous variables can take any numeric value within a given range, such as age, height, or weight.",
  "Time Interval":
    "Time interval variables measure the duration between two events, such as response time or time spent on a task.",
};

const TaskMonitor = ({
  sprintId,
  setIsSprintMonitor,
  stakeholderId,
  behaviorId,
  nudgeId,
  data,
  sprintDetails,
}) => {
  const [selectedStakeholderName, setSelectedStakeholderName] = useState(null);
  const [selectedBehaviorName, setSelectedBehaviorName] = useState(null);
  const [selectedNudgeName, setSelectedNudgeName] = useState(null);
  const [selectedSprinterName, setSelectedSprinterName] = useState(null);
  const [selectedSprinterId, setSelectedSprinterId] = useState(null);
  const [sprintName, setSprintName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tasks, setTasks] = useState([]);
  const [editingTask, setEditingTask] = useState(null);
  const [creating, setCreating] = useState(false);
  const [errorFields, setErrorFields] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);

  // Function to open dialog and set the task to delete
  const confirmDeleteTask = (taskId) => {
    setTaskToDelete(taskId);
    setIsDeleteDialogOpen(true);
  };

  // Function to close the dialog without deleting
  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setTaskToDelete(null);
  };

  const [createSprint] = useMutation(CREATE_SPRINT, {
    refetchQueries: [{ query: GET_ALL_SPRINTS }],
    onError: (error) => {
      console.error("Error creating sprint:", error);
      showToast("Failed to create sprint. Please try again.", "error");
    },
  });

  const [updateSprint] = useMutation(UPDATE_SPRINT, {
    refetchQueries: [{ query: GET_ALL_SPRINTS }],
    onError: (error) => {
      console.error("Error updating sprint:", error);
      alert(error);
      showToast("Failed to update sprint. Please try again.", "error");
    },
  });

  useEffect(() => {
    if (sprintId && sprintDetails) {
      // If sprintId exists, pre-fill the data from sprintDetails
      setSprintName(sprintDetails.name);
      setTasks(sprintDetails.tasks || []);
      setStartDate(sprintDetails.startDate || "");
      setEndDate(sprintDetails.endDate || "");
      setSelectedStakeholderName(sprintDetails.stakeholderName);
      setSelectedBehaviorName(sprintDetails.behaviorName);
      setSelectedNudgeName(sprintDetails.nudgeName);
      setSelectedSprinterName(sprintDetails.sprinterName);
      setSelectedSprinterId(sprintDetails.sprinterId);
    } else if (!sprintId) {
      // For new sprints, set names based on the provided stakeholder, behavior, and nudge IDs
      if (data && stakeholderId && behaviorId && nudgeId) {
        const stakeholder = data.children.find((s) => s.id === stakeholderId);
        const behavior = stakeholder?.children.find((b) => b.id === behaviorId);
        const nudge = behavior?.children.find((n) => n.id === nudgeId);

        setSelectedStakeholderName(stakeholder?.name || "");
        setSelectedBehaviorName(behavior?.name || "");
        setSelectedNudgeName(nudge?.name || "");
        setSelectedSprinterName(nudge?.children[0]?.name || "");
        setSelectedSprinterId(nudge?.children[0]?.sprinterId || "");
        setTasks(nudge.children[0].tasks);
      }
    }
  }, [sprintId, sprintDetails, data, stakeholderId, behaviorId, nudgeId]);

  const handleAddTask = () => {
    const newTask = {
      id: uuidv4(),
      name: null,
      variableType: null,
      valueType: null,
      // For Dichotomous and Categorical types, baseline is fixed to 0.
      baseline: 0,
      goal: null,
      termination: null,
      variableDescription: null,
      frequency: null,
      successValue: null,
      terminationValue: null,
      successDescription: null,
      terminationDescription: null,
      report: null,
    };
    setTasks([...tasks, newTask]);
    setEditingTask(newTask);
  };

  const handleTaskChange = (field, value) => {
    setEditingTask({ ...editingTask, [field]: value });
  };

  const handleSaveTask = () => {
    const validationError = validateTaskFields(editingTask);
    if (validationError) {
      showToast(validationError, "error");
      return;
    }

    const updatedTasks = tasks.map((task) =>
      task.id === editingTask.id ? editingTask : task
    );
    setTasks(updatedTasks);
    setEditingTask(null); // Exit edit mode
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
  };

  const validateTaskFields = (task) => {
    console.log(task);
    if (!task.variableType) return "Please select a variable type.";
    if (!task.name) return "Task name is required.";

    if (task.variableType === "Likert Scale" && task.baseline === null) {
      return "Baseline is required.";
    }
    if (
      task.variableType === "Continuous" &&
      (task.baseline === null ||
        task.goal === null ||
        task.termination === null ||
        !task.frequency)
    ) {
      return "Baseline, Goal, Termination, and Frequency are required for Continuous tasks.";
    }
    if (task.variableType === "Time Interval" && task.goal === null) {
      return "Goal is required for Time Interval tasks.";
    }
    return null;
  };

  const handleDeleteTask = () => {
    const updatedTasks = tasks.filter((task) => task.id !== taskToDelete);
    setTasks(updatedTasks);
    setIsDeleteDialogOpen(false); // Close the dialog
  };

  const handleCalculateValues = () => {
    const missingFields = [];
  
    if (!startDate) missingFields.push("Start Date");
    if (!endDate) missingFields.push("End Date");
    if (!editingTask?.baseline && editingTask?.baseline !== 0)
      missingFields.push("Baseline");
    if (!editingTask?.goal) missingFields.push("Goal");
    if (!editingTask?.frequency) missingFields.push("Frequency");

    if (missingFields.length === 0) {
      const days = differenceInDays(parseISO(endDate), parseISO(startDate));
      let totalPeriods = days > 0 ? days : 1; // Default is days
      console.log(days);
      // Adjust totalPeriods based on the frequency selected
      if (editingTask.frequency === "Hour") {
        totalPeriods = days > 0 ? days * 24 : 24; // Convert days to hours
      } else if (editingTask.frequency === "Week") {
        totalPeriods = days > 0 ? days / 7 : 1 / 7; // Convert days to weeks
      }
     
      if (totalPeriods > 0) {
        const baseline = editingTask.baseline;
        const goal = editingTask.goal;
        const termination = editingTask.termination;

        // Calculate success and termination per selected period, limiting to 2 decimal places
        const successPerPeriod = ((goal - baseline) / totalPeriods).toFixed(2);
        const terminationPerPeriod = (
          (baseline - termination) /
          totalPeriods
        ).toFixed(2);

        // Set the values in the current task
        setEditingTask({
          ...editingTask,
          successValue: parseFloat(successPerPeriod),
          terminationValue: parseFloat(terminationPerPeriod),
        });
      } else {
        showToast(
          "Invalid date range. Please check the start and end dates.",
          "error"
        );
      }
    } else {
      showToast(
        `Please fill in the missing fields: ${missingFields.join(", ")}`,
        "error"
      );
    }
  };
  const totalDays =
  startDate && endDate
    ? differenceInDays(parseISO(endDate), parseISO(startDate))
    : 0;

  const handleSaveSprint = async () => {
    if (!sprintName) {
      showToast("Sprint name is required.", "error");
      return;
    }
    if (!startDate) {
      showToast("Start date is required.", "error");
      return;
    }
    if (!endDate) {
      showToast("End date is required.", "error");
      return;
    }
    const incompleteTasks = tasks
      .map((task, index) => (validateTaskFields(task) ? task.name : null))
      .filter((index) => index !== null);

    if (incompleteTasks.length > 0) {
      showToast(
        <>
          Click on the Edit icon for these tasks listed and fill out the necessary details:
          <ul className="mt-2">
            {incompleteTasks.map((task, index) => (
              <li key={index}>
                {index + 1}. {task.substring(0, 25) + "...."}
              </li>
            ))}
          </ul>
        </>,
        "error"
      );
      setCreating(false); // Reset creating state
      return;
    }
    setCreating(true);

    const sprintData = {
      id: sprintId || uuidv4(),
      name: sprintName,
      tasks,
      startDate,
      endDate,
      stakeholderId,
      behaviorId,
      nudgeId,
      orgId: localStorage.getItem("orgId"),
      stakeholderName: selectedStakeholderName,
      behaviorName: selectedBehaviorName,
      nudgeName: selectedNudgeName,
      sprinterName: selectedSprinterName,
      sprinterId: selectedSprinterId,
      problemId: data.id,
      problemName: data.name,
      userId: localStorage.getItem("userId"),
      status: "In Tolerence",
    };

    if (sprintId) {
      await updateSprint({ variables: { sprintId, data: sprintData } });
    } else {
      await createSprint({ variables: { data: sprintData } });
    }

    setCreating(false);
    setIsSprintMonitor(false);
  };

  // Update the variable inputs based on type
  const renderVariableInputs = () => {
    switch (editingTask?.variableType) {
      case "Dichotomous":
        return (
          <div className="flex flex-row w-[100%] pb-3">
          <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
            Dichotomous Options
          </h1>
          <select
            disabled={!editingTask?.variableType}
            value={editingTask?.baseline || ""}
            onChange={(e) =>
              handleTaskChange("baseline", parseInt(e.target.value))
            }
            className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value={1}>Yes / No</option>
            <option value={2}>True / False</option>
            <option value={3}>Male / Female</option>
            <option value={4}>Greater / Lesser</option>
            <option value={5}>0 / 1</option>
          </select>
        </div>
        );
        case "Likert Scale":
          return (
            <div className="flex flex-row w-[45%] pb-3">
              <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                Categorical
              </h1>
              <select
                disabled={!editingTask?.variableType}
                value={editingTask?.baseline || ""}
                onChange={(e) =>
                  handleTaskChange("baseline", parseFloat(e.target.value))
                }
                className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
              >
                <option value="">Select Scale</option>
                <option value={1}>1-5</option>
                <option value={2}>1-7</option>
                <option value={3}>1-10</option>
              </select>
            </div>
          );
      case "Continuous":
        return (
          <div className="flex flex-col space-y-2 w-[100%]">
            {/* Baseline input */}
            <div className="flex flex-row w-full justify-between mb-2">
              <div className="w-[45%] space-y-1">
                <div className="flex flex-row w-full">
                  <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                    Baseline
                  </h1>
                  <input
                    type="number"
                    disabled={!editingTask?.variableType}
                    value={editingTask?.baseline || ""}
                    onChange={(e) =>
                      handleTaskChange("baseline", parseFloat(e.target.value))
                    }
                    className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                    placeholder="Enter baseline"
                  />
                </div>
              </div>

              {/* Goal input */}
              <div className=" flex flex-row  w-[45%] ">
                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                  Goal
                </h1>
                <input
                  type="number"
                  disabled={!editingTask?.variableType}
                  value={editingTask?.goal || ""}
                  onChange={(e) =>
                    handleTaskChange("goal", parseFloat(e.target.value))
                  }
                  className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                  placeholder="Enter goal"
                />
              </div>
            </div>

            {/* Termination input */}
            <div className="flex flex-row w-full pb-3 justify-between">
              <div className=" flex flex-row  w-[45%] ">
                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                  Termination
                </h1>
                <div className="w-[62%]">
                  <input
                    type="number"
                    disabled={!editingTask?.variableType}
                    value={editingTask?.termination || ""}
                    onChange={(e) =>
                      handleTaskChange("termination", parseFloat(e.target.value))
                    }
                    className="w-[100%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                    placeholder="Enter termination"
                  />
                  <h1 className="text-sm text-gray-500 text-end">
                    Enter -1 for 0
                  </h1>
                </div>
              </div>
              <div className="flex flex-row w-[45%] pb-3">
                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                  Frequency
                </h1>
                <select
                  disabled={!editingTask?.variableType}
                  value={editingTask?.frequency || ""}
                  onChange={(e) =>
                    handleTaskChange("frequency", e.target.value)
                  }
                  className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                >
                  <option value="">Select Frequency </option>
                  {/* <option value="Hour">Hourly</option> */}
                  <option value="Day">Daily</option>
                  {/* <option value="Week">Weekly</option> */}
                </select>
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={handleCalculateValues}
                className="w-[90%] bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
              >
                Calculate
              </button>
            </div>
            <div className="flex flex-row space-x-2 text-bcolor pt-3 justify-center">
              <h1 className="w-[60%] mt-2">
                Required Increase per {editingTask?.frequency} to Success:{" "}
              </h1>
              <input
                type="text"
                placeholder="Press calculate "
                value={editingTask?.successValue}
                disabled
                className={`w-[30%] h-[42px] rounded-[5px] border border-inputsborder p-2 ${
                  editingTask?.successValue ? "bg-[#EDE2FF]" : "bg-gray-300"
                }`}
              />
            </div>

            <div className="flex flex-row space-x-2 text-bcolor pt-3 pb-3 justify-center">
              <h1 className="mt-2 w-[60%]">
                Required Decrease per {editingTask?.frequency} to Terminate:
              </h1>
              <input
                type="text"
                value={editingTask?.terminationValue}
                placeholder="Press calculate"
                disabled
                className={`w-[30%] h-[42px] rounded-[5px] border border-inputsborder p-2 ${
                  editingTask?.terminationValue ? "bg-[#EDE2FF]" : "bg-gray-300"
                }`}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="shadow-md shadow-gray-400 bg-secondary flex flex-col rounded-[15px] w-full h-full font-roboto text-base overflow-scroll scrollbar border-[1px] border-gray-300">
      <ToastAlert />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        PaperProps={{
          style: {
            borderRadius: 28,
            background: "#990000",
            color : "white",
            width: "560px",
            height: "200px",
          },
        }}
      >
        <div className="p-6 ">
          <h2 className="text-lg font-semibold mb-4">
            Are you sure you want to delete this task?
          </h2>
          <p>This action cannot be undone.</p>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleCancelDelete}
              className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteTask}
              className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
      <div className="p-4 w-full space-y-4 overflow-y-scroll scrollbar">
        {!editingTask ? (
          <div className="p-4 w-full space-y-4 ">
            <div className="font-medium flex flex-col w-full justify-start border-b-[1px] border-gray-300 pb-1">
              <div className="flex flex-row w-full">
                <div className="flex flex-col justify-start space-y-1 w-[30%]">
                  <h1 className="font-semibold">Stakeholder</h1>
                  <h1 className="text-sm">{selectedStakeholderName}</h1>
                </div>
                <div className="flex flex-col space-y-1 w-[70%]">
                  <h1 className="font-semibold">Target Behavior</h1>
                  <h1 className="text-sm">{selectedBehaviorName}</h1>
                </div>
              </div>
            </div>

            <div className="font-medium flex flex-col w-full justify-start border-b-[1px] border-gray-300 pb-1">
              <div className="flex flex-row w-full">
                <div className="flex flex-col justify-start space-y-1 w-[30%]">
                  <h1 className="font-semibold">Nudge Type</h1>
                  <h1 className="text-sm">{selectedNudgeName}</h1>
                </div>
                <div className="flex flex-col space-y-1">
                  <h1 className="font-semibold">Sprinter</h1>
                  <h1 className="text-sm">{selectedSprinterName}</h1>
                </div>
              </div>
            </div>

            <div className="flex flex-row w-full pb-3  justify-between">
              <div className="flex flex-row w-[80%]">
                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center  text-black border border-inputsborder">
                  Sprint Name
                </h1>
                <input
                  type="text"
                  value={sprintName}
                  onChange={(e) => setSprintName(e.target.value)}
                  className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                />
              </div>
            </div>
            <div className="flex flex-row w-full pb-3 border-b-[1px] border-gray-300 justify-between">
              <div className="flex flex-row w-[45%]">
                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                  Start Date
                </h1>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                  className={`w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 ${
                    errorFields.startDate ? "border-red-500" : ""
                  }`}
                />
              </div>
              <div className="flex flex-row w-[45%]">
                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                  End Date
                </h1>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  placeholder="End Date"
                  className={`w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 ${
                    errorFields.endDate ? "border-red-500" : ""
                  }`}
                />
              </div>
            </div>

            <div className="flex flex-col space-y-2 pb-2  w-full">
              <h1 className="font-semibold"> Behavioral Nudges:</h1>
              <h1 className="text-sm text-gray-700 pb-3">
                Click on the edit icon to set success and termination measures{" "}
              </h1>
              <ul>
                {tasks.map((task) => (
                  <li key={task.id} className="flex items-center mb-2  pb-2 ">
                    <Edit
                      onClick={() => handleEditTask(task)}
                      className="cursor-pointer text-blue-500 mx-1"
                    />

                    <span className="w-full bg-inputsprimary rounded-[10px] px-5 py-3 border-[1px] border-inputsborder">
                      {task.name || `Task ${tasks.indexOf(task) + 1}`}
                    </span>
                    <Delete
                      onClick={() => confirmDeleteTask(task.id)}
                      className="cursor-pointer ml-2"
                    />
                  </li>
                ))}
                <button
                  onClick={handleAddTask}
                  className="bg-bcolor w-full rounded-[10px] px-6 py-4 text-white font-medium flex items-center justify-center border-t-[1px]"
                >
                  Click here to add a new behavioral nudge
                </button>
              </ul>
            </div>
            <div className="w-full flex justify-end pt-5">
              {creating ? (
                <button className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center">
                  Saving...
                </button>
              ) : (
                <button
                  onClick={handleSaveSprint}
                  className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
                >
                  Save Sprint
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="w-full pb-3 border-b-[1px] border-gray-300">
            <h1 className="font-semibold text-[18px] py-2 px-4">
              Interval Calculator
            </h1>
            <div className="flex flex-row w-[100%] pb-3 px-4">
              <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center  text-black border border-inputsborder">
                Behavioral Nudge
              </h1>
              <input
                type="text"
                value={editingTask?.name || ""}
                onChange={(e) => handleTaskChange("name", e.target.value)}
                className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                placeholder="Enter task name"
              />
            </div>
            <div className="flex flex-row w-[100%] pb-3 px-4">
              <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center text-black border border-inputsborder">
                Total Days
              </h1>
              <input
                type="text"
                value={totalDays || ""}
                disabled
                className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary"
              />
              </div>
            <div className="flex flex-row w-[100%] pb-3 px-4">
              <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center text-black border border-inputsborder">
                Variable Type
              </h1>
              <select
                value={editingTask?.variableType || ""}
                onChange={(e) =>
                  handleTaskChange("variableType", e.target.value)
                }
                className="w-[60%] h-[42px] rounded-r-[5px] border border-[#493971] p-2"
              >
                <option value="">Select Variable Type</option>
                <option value="Dichotomous">Dichotomous</option>
                <option value="Likert Scale">Categorical</option>
                <option value="Continuous">Continuous</option>
                <option value="Time Interval">Time Interval</option>
              </select>
            </div>
         
            {editingTask?.variableType && (
              <div className="px-4 pb-2">
                <p className="text-sm text-gray-600">
                  {variableTypeDescriptions[editingTask?.variableType]}
                </p>
              </div>
            )}
            {/* Variable Description */}
            <div className=" flex flex-row  w-[100%] px-4 pt-3">
              <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center text-black border border-inputsborder">
                Variable Description
              </h1>
              <input
                type="text"
                value={editingTask.variableDescription || ""}
                onChange={(e) =>
                  handleTaskChange("variableDescription", e.target.value)
                }
                disabled={!editingTask?.variableType}
                className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
              />
            </div>
            <div className="px-4 pt-2">
              <p className="text-sm text-gray-600">
                Enter the question you might ask. For example, if you are using
                a dichotomous measure the question could be: "Did the patient take the medication today?"
              </p>
            </div>
            {/* Interval Calculator and other fields */}

            <div className="p-4">
              {editingTask?.variableType !== "Continuous" && (
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-row w-[45%] pb-2">
                    <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                      Frequency
                    </h1>
                    <select
                      disabled={!editingTask?.variableType}
                      value={editingTask?.frequency || ""}
                      onChange={(e) =>
                        handleTaskChange("frequency", e.target.value)
                      }
                      className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                    >
                      <option value="">Select Frequency </option>
                      <option value="Day">Daily</option>
                    </select>
                  </div>
                  {editingTask?.variableType === "Dichotomous" ||
                  editingTask?.variableType === "Likert Scale" ? (
                    <p className="text-sm text-gray-600 pb-2">
                      Enter the absolute number of successful days (Goal) and the minimum number of successful days (Termination) required for the sprint. For example, for a 20‑day sprint, if you expect 16 successful days as your goal and want to terminate if only 7 successful days are reached, then enter 16 in Goal and 7 in Termination.
                    </p>
                  ) : (
                    <p className="text-sm text-gray-600 pb-2">
                      Enter a success and termination percentage for the variable type. For example, if you are running the sprint for 30 days and 60% is your success criteria and 20% or below is your termination criteria, enter 60 in Goal and 20 in Termination.
                    </p>
                  )}
                  <div className="flex flex-row w-full pb-3 justify-between">
                    <div className=" flex flex-row  w-[45%] ">
                      <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                        Goal
                      </h1>
                      <input
                        type="number"
                        disabled={!editingTask?.variableType}
                        value={editingTask?.goal || ""}
                        onChange={(e) =>
                          handleTaskChange("goal", parseFloat(e.target.value))
                        }
                        className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                        placeholder="Enter goal"
                      />
                    </div>
                    <div className=" flex flex-row  w-[45%] ">
                      <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                        Termination
                      </h1>
                      <div className="w-[60%] flex flex-col">
                        <input
                          type="number"
                          disabled={!editingTask?.variableType}
                          value={editingTask?.termination || ""}
                          onChange={(e) =>
                            handleTaskChange(
                              "termination",
                              parseFloat(e.target.value)
                            )
                          }
                          className="w-[100%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                          placeholder="Enter termination"
                        />
                        <h1 className="text-sm text-gray-500 text-end">
                          Enter -1 for 0
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col space-y-2 w-[100%]">
                {renderVariableInputs()}

                <div className="flex flex-row w-full pb-3">
                  {/* Success Description */}
                  <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center text-black border border-inputsborder">
                    Success Description
                  </h1>
                  <input
                    type="text"
                    disabled={!editingTask?.variableType}
                    value={editingTask?.successDescription || ""}
                    onChange={(e) =>
                      handleTaskChange("successDescription", e.target.value)
                    }
                    className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                    placeholder="Enter success description"
                  />
                </div>

                <div className="flex flex-row w-full pb-3">
                  {/* Termination Description */}
                  <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                    Termination Description
                  </h1>
                  <input
                    type="text"
                    disabled={!editingTask?.variableType}
                    value={editingTask?.terminationDescription || ""}
                    onChange={(e) =>
                      handleTaskChange("terminationDescription", e.target.value)
                    }
                    className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                    placeholder="Enter termination description"
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex space-x-3 justify-end">
              <button
                onClick={(e) => setEditingTask(false)}
                className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
              >
                Close
              </button>
              <button
                onClick={handleSaveTask}
                className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
              >
                Save Task
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskMonitor;
