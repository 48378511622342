import React, { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { NavLink } from "react-router-dom";
import profile from "../../Assets/Images/pprofile.png";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_SUCCESSFUL_NUDGES,
  GET_COMMENTS_FOR_NUDGE,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  UPDATE_CONCUR_FOR_NUDGE,
} from "../../Graphql/User/Query";
import LoadingIndicator from "../Common/LoadingIndicator";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { formatDistanceToNow, parseISO } from "date-fns";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const TutorialHowTo = () => {
  const [open, setOpen] = useState(true);
  const [contentWidth, setContentWidth] = useState("79.5%");
  const [selectedNudge, setSelectedNudge] = useState(null);

  // New state for the currently selected task
  const [selectedTask, setSelectedTask] = useState(null);

  // State for local votes (keyed by nudge ID)
  const [localUserVotes, setLocalUserVotes] = useState({});

  // Current user from localStorage
  const currentUser = localStorage.getItem("username");

  // Local state for new comment (top-level or reply)
  const [newComment, setNewComment] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);

  // Local state for editing a comment
  const [editCommentId, setEditCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");

  // Track expanded/collapsed state of each comment's replies
  const [expandedComments, setExpandedComments] = useState({});

  // Query: get all successful nudges (ensure this query returns concurVotes)
  const { data, loading, error } = useQuery(GET_ALL_SUCCESSFUL_NUDGES, {
    fetchPolicy: "network-only",
  });

  // Lazy Query: load comments only when a user selects a nudge
  const [
    loadComments,
    { data: commentData, loading: commentLoading, error: commentError, refetch },
  ] = useLazyQuery(GET_COMMENTS_FOR_NUDGE, {
    fetchPolicy: "network-only",
  });

  // Mutations: create, update, delete comments
  const [createComment] = useMutation(CREATE_COMMENT);
  const [updateComment] = useMutation(UPDATE_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  // Mutation: update concur vote
  const [updateConcurVote] = useMutation(UPDATE_CONCUR_FOR_NUDGE);

  useEffect(() => {
    setContentWidth(open ? "89.5%" : "96.5%");
  }, [open]);

  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error("Error fetching successful nudges:", error);
    showToast("Failed to load successful nudges", "error");
    return null;
  }

  // Build a tree from the flat array of comments
  const buildCommentTree = (comments) => {
    const commentMap = {};
    comments.forEach((comment) => {
      commentMap[comment.id] = { ...comment, replies: [] };
    });
    const commentTree = [];
    comments.forEach((comment) => {
      if (comment.parentCommentId) {
        const parent = commentMap[comment.parentCommentId];
        if (parent) {
          parent.replies.push(commentMap[comment.id]);
        }
      } else {
        commentTree.push(commentMap[comment.id]);
      }
    });
    return commentTree;
  };

  // Helper: Toggle whether a comment’s replies are visible
  const toggleReplies = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  // List of all successful nudges
  const successfulNudges = data?.getAllSuccessfulNudges || [];

  // Open modal & load comments
  const handleOpenModal = (nudge) => {
    setSelectedNudge(nudge);
    loadComments({ variables: { nudgeId: nudge.id } });
  };

  const handleCloseModal = () => {
    setSelectedNudge(null);
    setNewComment("");
    setReplyToCommentId(null);
    setEditCommentId(null);
    setEditCommentText("");
    setExpandedComments({});
  };

  // --- CREATE or REPLY ---
  const handleCreateComment = async () => {
    if (!newComment.trim() || !selectedNudge) return;
    try {
      await createComment({
        variables: {
          nudgeId: selectedNudge.id,
          data: {
            content: newComment,
            author: currentUser,
            parentCommentId: replyToCommentId,
          },
        },
      });
      setNewComment("");
      setReplyToCommentId(null);
      if (refetch) refetch();
    } catch (err) {
      console.error("Error creating comment:", err);
      showToast("Failed to create comment", "error");
    }
  };

  // --- EDIT ---
  const handleEditComment = (commentId, originalText) => {
    setEditCommentId(commentId);
    setEditCommentText(originalText);
  };

  const handleUpdateComment = async (commentId) => {
    if (!editCommentText.trim() || !selectedNudge) return;
    try {
      await updateComment({
        variables: {
          nudgeId: selectedNudge.id,
          commentId,
          data: { content: editCommentText },
        },
      });
      setEditCommentId(null);
      setEditCommentText("");
      if (refetch) refetch();
    } catch (err) {
      console.error("Error updating comment:", err);
      showToast("Failed to update comment", "error");
    }
  };

  // --- DELETE ---
  const handleDeleteComment = async (commentId) => {
    if (!window.confirm("Are you sure you want to delete this comment?")) return;
    try {
      await deleteComment({
        variables: { nudgeId: selectedNudge.id, commentId },
      });
      if (refetch) refetch();
    } catch (err) {
      console.error("Error deleting comment:", err);
      showToast("Failed to delete comment", "error");
    }
  };
  {/* Task Details Modal */ }
  // Helper function to determine the color class based on report status
  const getReportStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "text-green-500";
      case "In Tolerance":
        return "text-yellow-500";
      case "Termination":
        return "text-red-500";
      default:
        return "text-gray-600";
    }
  };
  // --- CONCUR VOTE ---
  const handleConcurVote = async (nudgeId, vote) => {
    // Update local state immediately
    setLocalUserVotes((prev) => ({ ...prev, [nudgeId]: vote }));
    try {
      await updateConcurVote({
        variables: {
          nudgeId,
          userId: currentUser,
          concur: vote,
        },
      });
      // No need to refetch; when the user leaves and returns, the query refreshes.
    } catch (err) {
      console.error("Error updating concur vote:", err);
      showToast("Failed to update your vote", "error");
      // Optionally, revert local state on error.
    }
  };

  // New: Open task details modal
  const handleOpenTaskModal = (task) => {
    setSelectedTask(task);
  };
  const handleCloseTaskModal = () => {
    setSelectedTask(null);
  };

  // Format time difference for "2 hours ago", etc.
  const formatTimeDifference = (createdAt) => {
    return formatDistanceToNow(parseISO(createdAt), { addSuffix: true });
  };

  // Recursively renders a SINGLE comment (including its replies if expanded)
  const renderComment = (comment) => {
    const isAuthor = comment.author === currentUser;
    const isEditing = editCommentId === comment.id;
    const hasReplies = comment.replies && comment.replies.length > 0;
    const isExpanded = expandedComments[comment.id] || false;
    return (
      <div key={comment.id} className="ml-3 my-3 border-l pl-3">
        <div className="flex justify-between">
          <div className="text-sm font-medium">{comment.author || "Anonymous"}</div>
          {comment.createdAt && (
            <div className="text-xs text-gray-400">
              {formatTimeDifference(comment.createdAt)}
            </div>
          )}
        </div>
        {!isEditing && <p className="text-sm">{comment.content}</p>}
        {isEditing && (
          <div className="mt-1">
            <textarea
              className="border rounded w-full p-1 text-sm"
              value={editCommentText}
              onChange={(e) => setEditCommentText(e.target.value)}
            />
            <div className="flex space-x-2 mt-1">
              <button
                onClick={() => handleUpdateComment(comment.id)}
                className="bg-blue-500 text-white px-2 py-1 rounded text-xs"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setEditCommentId(null);
                  setEditCommentText("");
                }}
                className="bg-gray-300 text-black px-2 py-1 rounded text-xs"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {!isEditing && (
          <div className="flex items-center space-x-3 mt-1 text-xs text-gray-600">
            <button onClick={() => setReplyToCommentId(comment.id)} className="hover:underline">
              Reply
            </button>
            {isAuthor && (
              <>
                <button
                  onClick={() => handleEditComment(comment.id, comment.content)}
                  className="hover:underline"
                >
                  Edit
                </button>
                <button onClick={() => handleDeleteComment(comment.id)} className="hover:underline">
                  Delete
                </button>
              </>
            )}
          </div>
        )}
        {hasReplies && (
          <div className="mt-1">
            {!isExpanded ? (
              <button
                onClick={() => toggleReplies(comment.id)}
                className="text-xs text-blue-500 underline"
              >
                View {comment.replies.length}{" "}
                {comment.replies.length === 1 ? "reply" : "replies"}
              </button>
            ) : (
              <>
                <button
                  onClick={() => toggleReplies(comment.id)}
                  className="text-xs text-blue-500 underline"
                >
                  Hide replies
                </button>
                <div className="ml-4">
                  {comment.replies.map((reply) => renderComment(reply))}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  // Renders the top-level array of comments (each with nested replies) in a styled container
  const renderCommentsList = (commentTree) => {
    if (!commentTree || commentTree.length === 0) {
      return <p className="text-sm text-gray-500">No comments yet. Be the first!</p>;
    }
    return (
      <div className="bg-white p-3 rounded-lg shadow max-h-[350px] overflow-auto">
        {commentTree.map((comment) => renderComment(comment))}
      </div>
    );
  };

  return (
    <div className="flex h-screen font-roboto bg-img text-base">
      {/* Sidebar */}
      <div style={{ width: open ? "22%" : "60px" }}>
        <Sidebar open={open} setOpen={setOpen} />
      </div>

      {/* Main Content */}
      <div className="flex flex-col w-full" style={{ width: contentWidth }}>
        {/* Header */}
        <div className="flex items-center h-[78px] bg-primary text-white font-medium pl-10 flex-shrink-0">
          <NavLink to="/tutorial">
            <button className="bg-bcolor rounded-full px-6 py-2.5 flex items-center text-white">
              <ArrowBackIcon /> <span className="ml-2">Back</span>
            </button>
          </NavLink>
          <h1 className="text-2xl ml-5 opacity-50">Tutorials and Resources</h1>
          <h1 className="text-2xl ml-2"> &gt; Successful Nudges</h1>
        </div>

        {/* Nudge Cards */}
        <div className="w-full pl-5">
          <h1 className="text-[28px] mb-5 mt-10 pl-5 text-white">Recent Successful Nudges</h1>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-6 px-5 pb-5">
            {successfulNudges.map((nudge) => {
              // Use local state if available; otherwise, fall back to the query data.
              const localVote = localUserVotes[nudge.id];
              const currentUserVote =
                localVote !== undefined
                  ? { userId: currentUser, concur: localVote }
                  : nudge.concurVotes?.find((vote) => vote.userId === currentUser);

              return (
                <div
                  key={nudge.id}
                  className="pb-3 bg-inputssecondary rounded-[15px] relative overflow-hidden"
                >
                  <div className="w-[100px] h-[20px] bg-inputssprimary rounded-[10px] shadow flex justify-center items-center">
                    <div className="text-center text-[#49454f]/50 text-[11px] font-medium pt-1">
                      {formatTimeDifference(nudge.createdAt)}
                    </div>
                  </div>
                  <div className="w-full flex flex-col justify-center items-center space-y-2">
                    <img
                      className="w-[140px] h-[140px] rounded-full shadow-md"
                      src={profile}
                      alt="Profile"
                    />
                    <div className="text-[#1d1b20] text-base font-normal">
                      {nudge.sprintData?.sprinterName}
                    </div>
                    <div className="w-[90%] text-[#49454f] text-xs font-normal">
                      {nudge.summary.substring(0, 120) + "..."}
                    </div>
                    {/* Concur Vote Buttons and Percentage */}
                    <div className="flex flex-col items-center">
                      <div className="flex items-center w-full mt-2 space-x-3">
                        <button
                          onClick={() => handleConcurVote(nudge.id, true)}
                          className={`flex items-center space-x-1 px-3 py-1 border rounded-full ${currentUserVote && currentUserVote.concur
                            ? "bg-green-500 text-white"
                            : "bg-gray-200 text-green-700"
                            }`}
                        >
                          <ThumbUpIcon fontSize="small" />
                          <span className="text-xs">I Concur</span>
                        </button>
                        <button
                          onClick={() => handleConcurVote(nudge.id, false)}
                          className={`flex items-center space-x-1 px-3 py-1 border rounded-full ${currentUserVote && !currentUserVote.concur
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-red-700"
                            }`}
                        >
                          <ThumbDownIcon fontSize="small" />
                          <span className="text-xs">Don't Concur</span>
                        </button>
                      </div>
                      <div className="mt-1">
                        {nudge.concurVotes && nudge.concurVotes.length > 0 ? (
                          (() => {
                            const totalVotes = nudge.concurVotes.length;
                            const concurCount = nudge.concurVotes.filter((vote) => vote.concur).length;
                            const percentage = (concurCount / totalVotes) * 100;
                            let percentageColor = "text-gray-600";
                            if (percentage < 30) {
                              percentageColor = "text-red-500";
                            } else if (percentage < 85) {
                              percentageColor = "text-yellow-500";
                            } else {
                              percentageColor = "text-green-500";
                            }
                            return (
                              <p className={`text-xs mt-2 ${percentageColor}`}>
                                {percentage.toFixed(0)}% Concur ({concurCount}/{totalVotes} votes)
                              </p>
                            );
                          })()
                        ) : (
                          <p className="text-xs text-gray-600">No votes yet.</p>
                        )}
                      </div>
                    </div>
                    <div className="w-[95%] flex justify-end">
                      <button
                        className="h-10 px-6 py-2.5 inline-flex text-[#65558f] shadow-sm shadow-black rounded-full"
                        onClick={() => handleOpenModal(nudge)}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Modal with Nudge Details and Comments */}
        <Dialog
          open={!!selectedNudge}
          onClose={handleCloseModal}
          PaperProps={{
            style: { borderRadius: 20, maxWidth: "1000px", width: "100%" },
          }}
        >
          <DialogContent>
            {selectedNudge && (
              <div className="p-4">
                <div className="grid grid-cols-2 gap-6">
                  {/* Left Column: Nudge Details */}
                  <div className="border-r pr-3">
                    <h2 className="text-xl font-semibold text-center mb-4">Nudge Details</h2>
                    <div className="space-y-2 text-sm">
                      <p>
                        <strong>Sprint Name:</strong> {selectedNudge.sprintData?.name}
                      </p>
                      <p>
                        <strong>Sprinter Name:</strong> {selectedNudge.sprintData?.sprinterName}
                      </p>
                      <p>
                        <strong>Stakeholder Name:</strong> {selectedNudge.sprintData?.stakeholderName}
                      </p>
                      <p>
                        <strong>Nudge Type:</strong> {selectedNudge.sprintData?.nudgeName}
                      </p>
                      <p>
                        <strong>Behavior Name:</strong> {selectedNudge.sprintData?.behaviorName}
                      </p>
                      <p>
                        <strong>Problem Name:</strong> {selectedNudge.sprintData?.problemName}
                      </p>
                      <p>
                        <strong>Start Date:</strong>{" "}
                        {new Date(selectedNudge.sprintData?.startDate).toLocaleDateString()}
                      </p>
                      <p>
                        <strong>End Date:</strong>{" "}
                        {new Date(selectedNudge.sprintData?.endDate).toLocaleDateString()}
                      </p>
                      <p>
                        <strong>Status:</strong> {selectedNudge.sprintData?.status}
                      </p>
                      <p>
                        <strong>Summary:</strong> {selectedNudge.summary}
                      </p>
                      <h3 className="font-medium mt-4">Tasks:</h3>
                      <ul className="space-y-2">
                        {selectedNudge.sprintData?.tasks?.map((task) => (
                          <li
                            key={task.id}
                            className="flex justify-between items-center border p-2 rounded-lg shadow-sm bg-white"
                          >
                            <span className="font-medium">{task.name}</span>
                            <button
                              onClick={() => handleOpenTaskModal(task)}
                              className="  text-red-500 "
                            >
                           <AddCircleOutlineIcon/>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Right Column: Comments Section */}
                  <div className="flex flex-col">
                    <h2 className="text-xl font-semibold text-center mb-4">Comments</h2>
                    {commentLoading && <p>Loading comments...</p>}
                    {commentError && (
                      <p className="text-red-500">
                        Error loading comments: {commentError.message}
                      </p>
                    )}
                    {(() => {
                      const comments = commentData?.getCommentsForNudge || [];
                      const commentTree = buildCommentTree(comments);
                      return renderCommentsList(commentTree);
                    })()}
                    <div className="mt-4 border-t pt-2">
                      {replyToCommentId ? (
                        <p className="text-xs text-gray-600 mb-1">Replying to a comment...</p>
                      ) : (
                        <p className="text-xs text-gray-600 mb-1">Add a new top-level comment...</p>
                      )}
                      <textarea
                        className="w-full border p-2 rounded"
                        rows={3}
                        placeholder="Add a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                      <div className="flex items-center mt-2 space-x-2">
                        <button
                          onClick={handleCreateComment}
                          className="bg-primary text-white rounded-full px-4 py-2"
                        >
                          Post
                        </button>
                        {replyToCommentId && (
                          <button
                            onClick={() => {
                              setReplyToCommentId(null);
                              setNewComment("");
                            }}
                            className="px-4 py-2 rounded-full bg-gray-300 text-black"
                          >
                            Cancel Reply
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleCloseModal}
                    className="px-4 py-2 rounded-full bg-bcolor text-white font-medium"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>



        <Dialog
          open={!!selectedTask}
          onClose={handleCloseTaskModal}
          PaperProps={{
            style: { borderRadius: 20, maxWidth: "800px", width: "100%" },
          }}
        >
          <DialogContent>
            {selectedTask && (
              <div className="p-4">
                <h2 className="text-2xl font-semibold mb-4 text-center">Task Report</h2>

                {/* Task Details Table */}
                <table className="min-w-full mb-6 border border-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Field
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {selectedTask.id && (
                      <tr>
                        <td className="px-4 py-2">ID</td>
                        <td className="px-4 py-2">{selectedTask.id}</td>
                      </tr>
                    )}
                    {selectedTask.name && (
                      <tr>
                        <td className="px-4 py-2">Task Name</td>
                        <td className="px-4 py-2">{selectedTask.name}</td>
                      </tr>
                    )}
                    {selectedTask.variableType && (
                      <tr>
                        <td className="px-4 py-2">Variable Type</td>
                        <td className="px-4 py-2">{selectedTask.variableType}</td>
                      </tr>
                    )}
                    {(selectedTask.baseline !== undefined && selectedTask.baseline !== null) && (
                      <tr>
                        <td className="px-4 py-2">Baseline</td>
                        <td className="px-4 py-2">{selectedTask.baseline}</td>
                      </tr>
                    )}
                    {(selectedTask.goal !== undefined && selectedTask.goal !== null) && (
                      <tr>
                        <td className="px-4 py-2">Goal</td>
                        <td className="px-4 py-2">{selectedTask.goal}</td>
                      </tr>
                    )}
                    {(selectedTask.termination !== undefined && selectedTask.termination !== null) && (
                      <tr>
                        <td className="px-4 py-2">Termination</td>
                        <td className="px-4 py-2">{selectedTask.termination}</td>
                      </tr>
                    )}
                    {selectedTask.variableDescription && (
                      <tr>
                        <td className="px-4 py-2">Variable Description</td>
                        <td className="px-4 py-2">{selectedTask.variableDescription}</td>
                      </tr>
                    )}
                    {selectedTask.frequency && (
                      <tr>
                        <td className="px-4 py-2">Frequency</td>
                        <td className="px-4 py-2">{selectedTask.frequency}</td>
                      </tr>
                    )}
                    {(selectedTask.successValue !== undefined && selectedTask.successValue !== null) && (
                      <tr>
                        <td className="px-4 py-2">Success Value</td>
                        <td className="px-4 py-2">{selectedTask.successValue}</td>
                      </tr>
                    )}
                    {(selectedTask.terminationValue !== undefined && selectedTask.terminationValue !== null) && (
                      <tr>
                        <td className="px-4 py-2">Termination Value</td>
                        <td className="px-4 py-2">{selectedTask.terminationValue}</td>
                      </tr>
                    )}
                    {selectedTask.successDescription && (
                      <tr>
                        <td className="px-4 py-2">Success Description</td>
                        <td className="px-4 py-2">{selectedTask.successDescription}</td>
                      </tr>
                    )}
                    {selectedTask.terminationDescription && (
                      <tr>
                        <td className="px-4 py-2">Termination Description</td>
                        <td className="px-4 py-2">{selectedTask.terminationDescription}</td>
                      </tr>
                    )}
                    {selectedTask.valueType && (
                      <tr>
                        <td className="px-4 py-2">Value Type</td>
                        <td className="px-4 py-2">{selectedTask.valueType}</td>
                      </tr>
                    )}
                    {selectedTask.status && (
                      <tr>
                        <td className="px-4 py-2">Status</td>
                        <td className="px-4 py-2">{selectedTask.status}</td>
                      </tr>
                    )}
                  </tbody>

                </table>

                {/* Report Data Table */}
                <h3 className="text-xl font-semibold mb-2">Report Data</h3>
                {selectedTask.report && selectedTask.report.length > 0 ? (
                  <table className="min-w-full border border-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Reported Date
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Reported Value
                        </th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {selectedTask.report.map((report, idx) => (
                        <tr key={idx}>
                          <td className="px-4 py-2">{report.reportedDate}</td>
                          <td className="px-4 py-2">{report.reportedValue}</td>
                          <td className={`px-4 py-2 ${getReportStatusColor(report.status)}`}>
                            {report.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-sm">No report data available for this task.</p>
                )}

                <div className="flex justify-end mt-4">
                  <button
                    onClick={handleCloseTaskModal}
                    className="px-4 py-2 rounded-full bg-bcolor text-white font-medium"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>


      </div>
    </div>
  );
};

export default TutorialHowTo;
