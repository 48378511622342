import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const CustomCheckbox = styled(Checkbox)({
  color: 'white', // Custom color for unchecked state
  '&.Mui-checked': {
    color: 'white', // Custom color for checked state
  },
});

export default function CustomAlertDialog({ onClose }) {
  const [open, setOpen] = React.useState(true); // Open on page load
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    if (checked) {
      localStorage.setItem('doNotShowDialog', 'true');
    }
    onClose(checked);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 28, background: '#990000', width: '560px', height: '260px' },
      }}
    >
      <DialogContent className="w-[560px] h-[260px]  rounded-[28px] p-6 flex flex-col justify-between font-roboto text-base">
        <div>
          <div className="text-white text-2xl font-normal leading-loose">
            Where to Start?
          </div>
          <div className="text-white font-semibold leading-tight tracking-tight mt-2">
            Start with “Generate Problem Statement” on top left!
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="text-white font-normal leading-normal tracking-wide">
            Don’t show this again!
          </div>
          <CustomCheckbox
            checked={checked}
            onChange={handleCheckboxChange}
            inputProps={{ 'aria-label': 'custom checkbox' }}
          />
        </div>
      </DialogContent>
      <div className="w-full flex pr-[60px] py-4 justify-end text-base">
        <button
          onClick={handleClose}
          className="px-3 py-2.5  rounded-full bg-bcolor text-white font-medium tracking-tight"
        >
          OK!
        </button>
      </div>
    </Dialog>
  );
}