import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import anplogo from "../Assets/Images/anplogo.png";
import video from "../Assets/Images/video.png";
import IU from "../Assets/Images/IU.png"; // Using IU.png as background
import ReactPlayer from "react-player";

const Behaviors = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleVideoToggle = () => {
    setIsVideoOpen(!isVideoOpen);
  };

  return (
    <div className="relative flex flex-col h-screen font-roboto text-base">
      {/* Background layer */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${IU})`,
          backgroundRepeat: "repeat",
          filter: "opacity(0.1) grayscale(190%)", // Adjust these values for desired visibility and grey tone
        }}
      />

      {/* Main content above background */}
      <div className="relative z-10 min-h-screen flex flex-col">
        <div className="min-h-screen flex flex-col relative">
          <div className="absolute inset-0 flex items-center justify-center text-white font-bold z-10 bg-opacity-[80%]">
            <div className="flex flex-col space-y-5 h-screen overflow-scroll w-screen scrollbar-hide justify-center items-center">
              {isVideoOpen ? (
                <div className="w-full h-full shadow-lg overflow-hidden flex justify-center items-center rounded-t-[10px] relative">
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=9BJy36xgH3Y"
                    playing
                    loop
                    controls={false}
                    width="82%"
                    height="82%"
                    className="shadow-lg"
                  />
                </div>
              ) : (
                <>
                  <img
                    src={anplogo}
                    alt="ANP Logo"
                    className="w-[386.65px] h-[346.38px]"
                  />
                  <div className="flex flex-row space-x-0 text-4xl">
                    <h1 className="text-white text-center font-[Forum] text-[64px] font-normal leading-normal tracking-[9.6px]">
                      AgileNudge
                    </h1>
                    <h1 className="text-white text-center font-[Forum] text-[64px] font-normal leading-normal tracking-[9.6px] -mt-3">
                      +
                    </h1>
                  </div>
                  <div className="flex flex-row w-[440px] justify-between">
                    <NavLink to="/login" state={{ isLogin: true }}>
                      <button className="h-10 rounded-full border border-white flex justify-center items-center px-6">
                        <span className="text-white font-medium">
                          Already Have an Account
                        </span>
                      </button>
                    </NavLink>

                    <NavLink to="/login" state={{ isLogin: false }}>
                      <button className="h-10 bg-button rounded-full flex justify-center items-center px-6">
                        <span className="text-white font-medium">
                          Create an Account
                        </span>
                      </button>
                    </NavLink>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="fixed bottom-1 right-5 rounded-full p-4 z-20 cursor-pointer"
        onClick={handleVideoToggle}
      >
        <img
          src={video}
          className="w-[80px] h-[80px] shadow rounded-full"
          alt="Take a tour"
        />
        <div className="text-center text-white font-normal leading-tight tracking-tight">
          {isVideoOpen ? "Close Tour" : "Take a Tour"}
        </div>
      </div>
    </div>
  );
};

export default Behaviors;
