import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { GENERATE_PROBLEM } from "../../Graphql/User/Query";
import { useNavigate } from "react-router-dom";
import ToastAlert, { showToast } from "../Common/ToastAlert";

import Sidebar from "./SideBar";
import CustomAlertDialog from "./CustomAlertDialog";
import LandingVideo from "./LandingVideo";

const CreateProblem = () => {
  const [challenge, setChallenge] = useState("");
  const [instructions, setInstructions] = useState([]);
  const [problemStatement, setProblemStatement] = useState(
    localStorage.getItem("problemStatement") || null
  );

  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false); 
  useEffect(() => {
    if (!localStorage.getItem("doNotShowDialog")) {
      setShowDialog(true);
    }
  }, []);
  const handleDialogClose = (doNotShowAgain) => {
    if (doNotShowAgain) {
      localStorage.setItem("doNotShowDialog", "true");
    }
    setShowDialog(false);
  };
  const [generateProblemStatement, { loading: generatingProblem }] =
    useMutation(GENERATE_PROBLEM, {
      onCompleted: (data) => {
        setProblemStatement(data.generateProblemStatement);
     
      },
      onError: (error) => {
        console.error("Error generating problem statement:", error);
        showToast("Failed to generate problem statement", "error");
      },
    });
useEffect(() => {
  localStorage.setItem("challenge", "")
},[])
  const handleGenerate = () => {
    if (!challenge.trim()) {
      showToast("Please enter a detailed description of the challenge.", "error");
      return;
    }
    // Add current challenge to instructions and generate statement
    const updatedInstructions = [...instructions, challenge];
    setInstructions(updatedInstructions);
    localStorage.setItem("challenge", updatedInstructions.join("."))
    generateProblemStatement({
      variables: { challenge: JSON.stringify(updatedInstructions.join(".")) },
    });

    // Clear the input for the next instruction
    setChallenge("");
  };

  const handleCreateProblem = () => {
    // Clear instructions after generating the problem statement
    setInstructions([]);
    localStorage.removeItem("problemStatement"); // Optionally clear localStorage if needed
    navigate("/demandindex", { state: { problemStatement, demand: [], challenge : localStorage.getItem('challenge') } });
  };

  // Store problem statement in localStorage for persistence across refreshes
  useEffect(() => {
    if (problemStatement) {
      localStorage.setItem("problemStatement", problemStatement);
    }
  }, [problemStatement]);
  if (localStorage.getItem("videosViewed") !== "true") {
    return <LandingVideo />; // Show LandingVideo component if videos have not been viewed
  }
  return (
    <div className="w-screen h-screen font-roboto flex justify-between bg-back">
      <ToastAlert />
      <Sidebar open={open} setOpen={setOpen} />
      <div className="flex flex-col w-[46%] h-screen justify-start items-center space-y-5 bg-img">
        <div className="w-full h-[70px] bg-primary flex justify-between px-4 items-center">
       
        </div>

        <div className="w-[90%] text-zinc-800 text-[28px] flex items-center space-x-3">
          <p>What is the challenge you are struggling with?</p>
        </div>

        <div className="w-full flex flex-col justify-center items-center h-[25%]">
          <textarea
            rows={4}
            value={challenge}
            onChange={(e) => setChallenge(e.target.value)}
            className="h-[100%] w-[90%] bg-secondary rounded-tl rounded-tr pl-4 py-1 text-[#1d1b20] text-[16px] border-b border-black resize-none"
            placeholder="Enter a detailed description of the challenge you are facing."
          />

          <div className="text-[#49454f] text-xs w-[90%] py-1 px-3">
            Come up with a short and concise challenge. Then press “Generate the Problem Statement” button.
          </div>
        </div>

        <div className="w-[90%] flex justify-end">
          <button
            onClick={handleGenerate}
            className={`h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center ${
              challenge.length < 10 ? "bg-bcolor text-white" : "bg-bcolor"
            }`}
            disabled={challenge.length < 10 || generatingProblem}
          >
            {generatingProblem
              ? "Generating..."
              : instructions.length
              ? "Revise the Problem"
              : "Generate the Problem Statement"}
          </button>
        </div>

        <div className="w-[90%] text-zinc-800 text-[28px] mt-5">
          <p>Your Problem Statement Is:</p>
        </div>

        <div className="w-full flex flex-col justify-center items-center h-[25%]">
          <div className="h-[100%] w-[90%] bg-tertiary rounded-tl rounded-tr pl-4 py-1 text-[#1d1b20] text-[16px] border-b border-black">
            {problemStatement || ""}
          </div>
          <div className="text-[#625b71] text-xs w-[90%]  py-1 px-3">
            If you agree with this problem statement, press “Continue to Check the Demand” button.
          </div>
        </div>

        <div className="w-[90%] flex justify-end">
          <button
            onClick={handleCreateProblem}
            className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
          >
            Continue to Check the Demand
          </button>
        </div>
      </div>
      {showDialog && <CustomAlertDialog onClose={handleDialogClose} />}
    </div>
  );
};

export default CreateProblem;