import { gql } from "@apollo/client";

// User Queries
export const GET_USER = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      username
      email
      profilepic
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    allUsers {
      id
      username
      email
      profilepic
    }
  }
`;

// Problem Queries
export const GET_PROBLEM = gql`
  query GetProblem($problemId: ID!) {
    getProblem(problemId: $problemId) {
      id
      name
      admins
      orgId
      startDate
      endDate
      FavTrash
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            goalMetric
            terminationMetric
            children {
              id
              name
              tasks {
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  reportedDate
                  reportedValue
                  status
                }
                status
                valueType
              }
              ismanual
              sprinterId
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_PROBLEMS = gql`
  query GetAllProblems($orgId: ID!) {
    getAllProblems(orgId: $orgId) {
      id
      name
      admins
      orgId
      startDate
      endDate
      FavTrash
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            goalMetric
            terminationMetric
            children {
              id
              name
              tasks {
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  reportedDate
                  reportedValue
                  status
                }
                status
                valueType
              }
              ismanual
              sprinterId
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_USER_PROBLEMS = gql`
  query GetAllUserProblems($userId: ID!) {
    getAllUserProblems(userId: $userId) {
      id
      name
      admins
      orgId
      startDate
      endDate
      FavTrash
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            goalMetric
            terminationMetric
            children {
              id
              name
              tasks {
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  reportedDate
                  reportedValue
                  status
                }
                status
                valueType
              }
              ismanual
              sprinterId
            }
          }
        }
      }
    }
  }
`;

// Sprint Queries
export const GET_SPRINT = gql`
  query GetSprint($sprintId: ID!) {
    getSprint(sprintId: $sprintId) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sentToSuccess
      sprinterName
      sprinterId
orgId
      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

export const GET_ALL_USER_SPRINTS = gql`
  query GetAllUserSprints($userId: ID!) {
    getAllUserSprints(userId: $userId) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      sentToSuccess
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId
orgId
      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

export const GET_ALL_SPRINTS = gql`
  query GetAllSprints {
    getAllSprints {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      sentToSuccess
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId
orgId
      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

// Organization Queries
export const GET_ORGANIZATION = gql`
  query GetOrganization($orgId: ID!) {
    getOrganization(orgId: $orgId) {
      id
      name
      members {
        userId
        accessLevel
      }
    }
  }
`;

export const GET_ALL_ORGANIZATIONS = gql`
  query GetAllOrganizations {
    getAllOrganizations {
      id
      name
        members {
        userId
        accessLevel
      }
    }
  }
`;
export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: OrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      members {
        userId
        accessLevel
      }
    }
  }
`;

// Mutation to update an existing organization
export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($orgId: ID!, $input: OrganizationInput!) {
    updateOrganization(orgId: $orgId, input: $input) {
      id
      name
      members {
        userId
        accessLevel
      }
    }
  }
`;

// Mutation to delete an organization
export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($orgId: ID!) {
    deleteOrganization(orgId: $orgId)
  }
`;

export const GET_USER_ORGANIZATIONS = gql`
  query GetUserOrganizations($userId: ID!) {
    getUserOrganizations(userId: $userId) {
      id
      name
         members {
        userId
        accessLevel
      }
    }
  }
`;

export const CREATE_ROOT_PROBLEM = gql`
  mutation CreateRootProblem($data: RootProblemInput!) {
    createRootProblem(data: $data) {
      id
      name
      admins
      orgId
      startDate
      endDate
      FavTrash
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            goalMetric
            terminationMetric
            children {
              id
              name
              tasks {
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  reportedDate
                  reportedValue
                  status
                }
                status
                valueType
              }
              ismanual
              sprinterId
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ROOT_PROBLEM = gql`
  mutation UpdateRootProblem($problemId: ID!, $data: RootProblemInput!) {
    updateRootProblem(problemId: $problemId, data: $data) {
      id
      name
      admins
      orgId
      startDate
      endDate
      FavTrash
      demand {
        ExecutiveSupport
        ServiceLineAdministrativeEngagement
        ClinicianEngagement
        StrengthOfSupportingEvidence
        ImpactToTheQuadrupleAim
        PotentialToScaleUp
        AdjustedScore
        Grade
      }
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            goalMetric
            terminationMetric
            children {
              id
              name
              tasks {
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  reportedDate
                  reportedValue
                  status
                }
                status
                valueType
              }
              ismanual
              sprinterId
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ROOT_PROBLEM = gql`
  mutation DeleteRootProblem($problemId: ID!) {
    deleteRootProblem(problemId: $problemId)
  }
`;

export const CREATE_SPRINT = gql`
  mutation CreateSprint($data: SprintInput!) {
    createSprint(data: $data) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId
orgId
sentToSuccess
      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

export const UPDATE_SPRINT = gql`
  mutation UpdateSprint($sprintId: ID!, $data: SprintInput!) {
    updateSprint(sprintId: $sprintId, data: $data) {
      id
      name
      userId
      nudgeId
      nudgeName
      problemId
      problemName
      status
      behaviorId
      behaviorName
      stakeholderId
      sprinterName
      sprinterId
orgId
sentToSuccess
      stakeholderName
      startDate
      endDate
      tasks {
        status
        valueType

        id
        name
        variableType
        baseline
        goal
        termination
        variableDescription
        frequency
        successValue
        terminationValue
        successDescription
        terminationDescription
        report {
          status
          reportedDate
          reportedValue
        }
      }
    }
  }
`;

export const DELETE_SPRINT = gql`
  mutation DeleteSprint($sprintId: ID!) {
    deleteSprint(sprintId: $sprintId)
  }
`;





export const GENERATE_PROBLEM = gql`
  mutation GenerateProblemStatement($challenge: String!) {
    generateProblemStatement(challenge: $challenge)
  }
`;

export const GENERATE_MAP = gql`
  mutation GenerateMap($challenge: String!, $problemStatement: String!) {
    generateMap(challenge: $challenge, problemStatement: $problemStatement) {
      id
      name
      children {
        id
        name
        children {
          id
          name
          target
          children {
            id
            name
            children {
              id
              name
              tasks {
                status
                valueType
                id
                name
                variableType
                baseline
                goal
                termination
                variableDescription
                frequency
                successValue
                terminationValue
                successDescription
                terminationDescription
                report {
                  status
                  reportedDate
                  reportedValue
                }
              }
            }
            goalMetric
            terminationMetric
          }
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $username: String!, $profilepic: String!) {
    updateUser(id: $id, username: $username, profilepic: $profilepic) {
      id
      username
      profilepic
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($username: String!, $email: String!) {
    createUser(username: $username, email: $email) {
      id
      username
      email
      profilepic
    }
  }
`;
export const GET_SUCCESSFUL_NUDGE = gql`
  query GetSuccessfulNudge($id: ID!) {
    getSuccessfulNudge(id: $id) {
      id
           concurVotes {
        userId
        concur
      }
      sprintData {
        id
        name
        nudgeId
        userId
        nudgeName
        problemId
        problemName
        behaviorId
        behaviorName
        stakeholderId
        initialValue
        report {
          reportedDate
          reportedValue
          status
        }
        sprinterId
        sprinterName
        stakeholderName
        startDate
        endDate
        tasks {
          id
          name
          variableType
          baseline
          goal
          termination
          variableDescription
          frequency
          successValue
          terminationValue
          successDescription
          terminationDescription
          valueType
          status
          report {
            reportedDate
            reportedValue
            status
          }
        }
        status
        orgId
      }
      summary
      createdAt
    }
  }
`;

// Query to get all SuccessfulNudges
export const GET_ALL_SUCCESSFUL_NUDGES = gql`
  query GetAllSuccessfulNudges {
    getAllSuccessfulNudges {
      id
           concurVotes {
        userId
        concur
      }
      sprintData {
        id
        name
        nudgeId
        userId
        nudgeName
        problemId
        problemName
        behaviorId
        behaviorName
        stakeholderId
        initialValue
        report {
          reportedDate
          reportedValue
          status
        }
        sprinterId
        sprinterName
        stakeholderName
        startDate
        endDate
        tasks {
          id
          name
          variableType
          baseline
          goal
          termination
          variableDescription
          frequency
          successValue
          terminationValue
          successDescription
          terminationDescription
          valueType
          status
          report {
            reportedDate
            reportedValue
            status
          }
        }
        status
        orgId
      }
      summary
      createdAt
    }
  }
`;

// Mutation to create a SuccessfulNudge
export const CREATE_SUCCESSFUL_NUDGE = gql`
  mutation CreateSuccessfulNudge($data: SuccessfulNudgeInput!) {
    createSuccessfulNudge(data: $data) {
      id
           concurVotes {
        userId
        concur
      }
      sprintData {
        id
        name
        nudgeId
        userId
        nudgeName
        problemId
        problemName
        behaviorId
        behaviorName
        stakeholderId
        initialValue
        report {
          reportedDate
          reportedValue
          status
        }
        sprinterId
        sprinterName
        stakeholderName
        startDate
        endDate
        tasks {
          id
          name
          variableType
          baseline
          goal
          termination
          variableDescription
          frequency
          successValue
          terminationValue
          successDescription
          terminationDescription
          valueType
          status
          report {
            reportedDate
            reportedValue
            status
          }
        }
        status
        orgId
      }
      summary
      createdAt
    }
  }
`;

// Mutation to update a SuccessfulNudge
export const UPDATE_SUCCESSFUL_NUDGE = gql`
  mutation UpdateSuccessfulNudge($id: ID!, $data: SuccessfulNudgeInput!) {
    updateSuccessfulNudge(id: $id, data: $data) {
      id
           concurVotes {
        userId
        concur
      }
      sprintData {
        id
        name
        nudgeId
        userId
        nudgeName
        problemId
        problemName
        behaviorId
        behaviorName
        stakeholderId
        initialValue
        report {
          reportedDate
          reportedValue
          status
        }
        sprinterId
        sprinterName
        stakeholderName
        startDate
        endDate
        tasks {
          id
          name
          variableType
          baseline
          goal
          termination
          variableDescription
          frequency
          successValue
          terminationValue
          successDescription
          terminationDescription
          valueType
          status
          report {
            reportedDate
            reportedValue
            status
          }
        }
        status
        orgId
      }
      summary
      createdAt
    }
  }
`;

// Mutation to delete a SuccessfulNudge
export const DELETE_SUCCESSFUL_NUDGE = gql`
  mutation DeleteSuccessfulNudge($id: ID!) {
    deleteSuccessfulNudge(id: $id)
  }
`;
export const GET_COMMENTS_FOR_NUDGE = gql`
  query GetCommentsForNudge($nudgeId: ID!) {
    getCommentsForNudge(nudgeId: $nudgeId) {
      id
      content
      author
      createdAt
      updatedAt
      parentCommentId
   
    }
  }
`;

// 2. Mutation: Create a new comment
export const CREATE_COMMENT = gql`
  mutation CreateComment($nudgeId: ID!, $data: CommentInput!) {
    createComment(nudgeId: $nudgeId, data: $data) {
      id
      content
      author
      createdAt
      parentCommentId
    }
  }
`;

// 3. Mutation: Update an existing comment
export const UPDATE_COMMENT = gql`
  mutation UpdateComment($nudgeId: ID!, $commentId: ID!, $data: CommentInput!) {
    updateComment(nudgeId: $nudgeId, commentId: $commentId, data: $data) {
      id
      content
      author
      updatedAt
      parentCommentId
    }
  }
`;

// 4. Mutation: Delete a comment
export const DELETE_COMMENT = gql`
  mutation DeleteComment($nudgeId: ID!, $commentId: ID!) {
    deleteComment(nudgeId: $nudgeId, commentId: $commentId)
  }
`;


export const UPDATE_CONCUR_FOR_NUDGE = gql`
  mutation UpdateConcurForNudge($nudgeId: ID!, $userId: ID!, $concur: Boolean!) {
    updateConcurForNudge(nudgeId: $nudgeId, userId: $userId, concur: $concur) {
      id
      concurVotes {
        userId
        concur
      }
    }
  }
`;
