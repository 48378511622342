import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const CustomCheckbox = styled(Checkbox)({
  color: 'white', // Custom color for unchecked state
  '&.Mui-checked': {
    color: 'white', // Custom color for checked state
  },
});

export default function NewProblemAlert({ onClose }) {
  const [open, setOpen] = React.useState(true); // Open on page load
  const [checked, setChecked] = React.useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    if (checked) {
      localStorage.setItem('doNotShowNewProblemDialog', 'true');
    }
    onClose(checked);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 28, background: '#990000', width: '560px', height: '320px' },
      }}
    >
      <DialogContent className="w-[560px] h-[320px] bg-primary rounded-[28px] p-6 flex flex-col justify-between font-roboto text-base">
        <div>
          <div className="text-white text-2xl font-normal leading-loose">
          Your map is generated now!
          </div>
          <div className="text-white font-semibold leading-tight tracking-tight mt-7">
          Click on <div class="h-4 w-4 bg-[#31111d] rounded-full justify-center items-center inline-flex ">
  <div class="grow shrink basis-0 text-center text-white text-[11px] font-medium leading-none tracking-wide w-3 h-3">+</div>
</div> to add a new stakeholder/ behavior/nudge type.
          </div>
          <div className="text-white font-semibold leading-tight tracking-tight mt-5 mb-3 flex flex-row space-x-1 justify-center items-center">
         <h1>Click on</h1>  <div class="w-[89px] h-[29px] bg-white rounded-[100px] shadow shadow-md flex-col justify-center items-center gap-2 inline-flex">
  <div class="self-stretch grow shrink basis-0 px-6 py-2.5 justify-center items-center gap-2 inline-flex">
    <div class="text-center"></div>
  </div>
</div> <h1>buttons to edit the stakeholder/behavior/ nudge variable.</h1>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <div className="text-white font-normal leading-normal tracking-wide text-[16px]">
            Don’t show this again!
          </div>
          <CustomCheckbox
            checked={checked}
            onChange={handleCheckboxChange}
            inputProps={{ 'aria-label': 'custom checkbox' }}
          />
        </div>
      </DialogContent>
      <div className="w-full flex pr-[70px] py-4 justify-end text-base">
        <button
          onClick={handleClose}
          className="px-3 py-2.5  rounded-full bg-bcolor text-white font-medium tracking-tight"
        >
          OK!
        </button>
      </div>
    </Dialog>
  );
}