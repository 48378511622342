import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import TreeDiagram from "./TreeDiagram";
import { ReactComponent as PrintIcon } from "../../Assets/Svgs/print.svg";
import {
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  parseISO,
} from "date-fns";
import { Height } from "@mui/icons-material";
const PrintView = () => {
  const location = useLocation();
  const { problem, sprints } = location.state || {};
  const targetRef = useRef();
  const handlePrint = async () => {
    const canvas = await html2canvas(targetRef.current);
    const imgData = canvas.toDataURL("image/png");
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          <img src="${imgData}" style="width:100%">
        </body>
      </html>
    `);
    printWindow.document.close();
  };
  const calculateReportStatus = (reportedValue, task, index) => {
    let successThreshold, terminationThreshold, midValue;

    switch (task.variableType) {
      case "Dichotomous":
        // Dichotomous: 1 is Success, 0 is Termination
        return reportedValue === 1
          ? { className: "bg-[#8BC34A]", statusText: "Success" }
          : { className: "bg-[#B3261E]", statusText: "Termination" };

      case "Likert Scale":
        // Likert Scale: success if > midpoint, termination if <= midpoint
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        midValue = range / 2;
        return reportedValue > midValue
          ? { className: "bg-[#8BC34A]", statusText: "Success" }
          : { className: "bg-[#B3261E]", statusText: "Termination" };

      case "Continuous":
        // Continuous: Success if cumulative baseline met, Termination if cumulative termination met
        const now = new Date();
        let daysElapsed = 0;

        if (task.frequency === "Hour") {
          daysElapsed = sprints.startDate
            ? differenceInHours(now, sprints.startDate)
            : 0;
        } else if (task.frequency === "Day") {
          daysElapsed = sprints.startDate
            ? differenceInDays(now, sprints.startDate)
            : 0;
        } else if (task.frequency === "Week") {
          daysElapsed = sprints.startDate
            ? differenceInWeeks(now, sprints.startDate)
            : 0;
        }
        // Calculate cumulative thresholds
        const cumulativeBaseline =
          task.baseline + task.successValue * daysElapsed;
        const cumulativeTermination =
          task.baseline - task.terminationValue * daysElapsed;

        // Calculate cumulative reported sum up to the current report's index
        const previousTotalReportedSum = (task.report || [])
          .slice(0, index) // Sum only up to the specified report index
          .reduce((sum, report) => sum + report.reportedValue, 0);

        const cumulativeReportedValue =
          previousTotalReportedSum + reportedValue + task.baseline;

        // Determine status based on cumulative comparison with thresholds
        if (cumulativeReportedValue >= cumulativeBaseline) {
          return { className: "bg-[#8BC34A]", statusText: "Success" };
        } else if (cumulativeReportedValue <= cumulativeTermination) {
          return { className: "bg-[#B3261E]", statusText: "Termination" };
        } else {
          return { className: "bg-[#FFBD2E]", statusText: "In Tolerance" };
        }

      case "Time Interval":
        // Time Interval: Success if >= goal, Termination if < goal
        successThreshold = task.goal;
        terminationThreshold = task.termination;
        return reportedValue >= successThreshold
          ? { className: "bg-[#8BC34A]", statusText: "Success" }
          : { className: "bg-[#B3261E]", statusText: "Termination" };

      default:
        // Default case if no type matches
        return { className: "bg-[#FFBD2E]", statusText: "In Tolerance" };
    }
  };
  if (!problem || !sprints) {
    return <div>No problem or sprints found.</div>;
  }

  return (
    <div className="bg-white p-10 w-screen flex flex-col justify-center items-center">
      <div className="w-full flex justify-end space-x-3">
        <a href="/nudgebuilder/custombuilder" className="h-10 bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center"
        >Back</a>
        <button className="w-12 h-12" onClick={handlePrint}><PrintIcon style={{ Width: "15px", Height: "15px" }} /></button>
      </div>
      <div
        ref={targetRef}
        className=" border-2 border-gray-400 rounded-lg text-base font-medium w-[100%]"
      >
        {/* Problem Statement */}
        <div className="bg-primary h-[70px]  p-4  rounded-t-lg">
          <div className="flex flex-row w-[100%]">
            <h1 className="font-medium bg-[#493971] min-h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
              Problem Statement
            </h1>
            <div className="w-[60%] min-h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
              {problem.name}
            </div>
          </div>
        </div>
        <div className="my-6">
          <h2 className="text-center text-lg font-semibold mb-4">Problem Map</h2>
          <h2 className="text-sm text-gray-500 text-center font-semibold mb-2">Scroll down the map to view the MSOP details. Please place the map in view to print.</h2>
          <TreeDiagram
            data={problem}
            sprints={sprints}
            isZoomEnabled={true}
            addChild={() => { }}
            handleNodeClick={() => { }}
            open={true}
          />
        </div>
        <h2 className="text-center text-lg font-semibold mb-4">MSOP Data</h2>
        {/* Stakeholders and Hierarchy */}
        {problem.children.map((stakeholder) => (
          <div key={stakeholder.id} className="mt-4 p-4">
            {/* Stakeholder Name */}
            <div className="bg-[#493971] text-center text-white p-4 font-bold rounded-[10px]">
              {stakeholder.name}
            </div>
            <div className="">
              {stakeholder.children.map((behavior) => (
                <div key={behavior.id} className="mt-4 p-2">
                  <div className="flex flex-row w-[100%]">
                    <h1 className="font-medium bg-[#6750A4] min-h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                      Target Behavior
                    </h1>
                    <div className="w-[60%] min-h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
                      {behavior.name}
                    </div>
                  </div>
                  {/* Nudge Types and Sprints */}
                  {behavior.children.map((nudge) => (
                    <div key={nudge.id} className="px-2 mt-2 mb-4">
                      <div className="flex flex-row justify-between">
                        <div className="bg-[#6750a4] text-white px-2 py-3 rounded font-semibold mb-2  w-[40%] text-center flex justify-center items-center rounded-[10px]">
                          Nudge Type: {nudge.name}
                        </div>
                        <div className="bg-[#6750a4] text-white px-2 py-3 rounded font-semibold mb-2 w-[40%] text-center flex justify-center items-center rounded-[10px]">
                          Sprinter: {nudge.children[0]?.name}
                        </div>
                      </div>
                      {/* Nudge Type */}

                      {sprints
                        .filter((sprint) => sprint.nudgeId === nudge.id)
                        .map((sprint) => (
                          <div key={sprint.id} className=" mt-4">
                            {/* Sprint Info */}
                            <div className="flex justify-between">
                              <p>.........................</p>
                              <p>
                                <strong>Start Date:</strong> {sprint.startDate}
                              </p>
                              <p>
                                <strong>End Date:</strong> {sprint.endDate}
                              </p>
                              <p>.........................</p>
                            </div>

                            {sprint.tasks.map((task) => (
                              <div key={task.id} className="mt-4">
                                {/* Task Information */}
                                <div className="flex flex-row w-[100%] my-3">
                                  <h1 className="font-medium bg-[#6750A4] min-h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                    Nudge Task
                                  </h1>
                                  <div className="w-[60%] min-h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
                                    {task.name}
                                  </div>
                                </div>
                                <div className="flex flex-row w-[40%] my-3">
                                  <h1 className="font-medium bg-[#6750A4] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                    Variable Type
                                  </h1>
                                  <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
                                    {task.variableType}
                                  </div>
                                </div>
                                <div className="flex flex-row w-[100%] my-3">
                                  <h1 className="font-medium bg-[#6750A4] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                    Success Description
                                  </h1>
                                  <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
                                    {task.successDescription}
                                  </div>
                                </div>
                                <div className="flex flex-row w-[100%] my-3">
                                  <h1 className="font-medium bg-[#6750A4] h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-white border border-[#4f378b]">
                                    Termination Description
                                  </h1>
                                  <div className="w-[60%] h-[42px] rounded-r-[5px] border border-[#4f378b] p-2 bg-white">
                                    {task.terminationDescription}
                                  </div>
                                </div>

                                {/* Reported Values */}
                                <div className="mt-4">
                                  {task.report && task.report.length > 0 ? (
                                    task.report.map((report, index) => {
                                      const { className, statusText } =
                                        calculateReportStatus(
                                          report.reportedValue,
                                          task,
                                          index
                                        );
                                      return (
                                        <div
                                          key={index}
                                          className="flex justify-between mt-2 w-full"
                                        >
                                          <div className="flex items-center w-[30%]">
                                            <p className="bg-[#6750A4] text-white p-2 rounded-l-md w-[50%]">
                                              Date of Report
                                            </p>
                                            <p className="bg-gray-300 p-2 rounded-r-md w-[50%]">
                                              {report.reportedDate.substring(0, 10)}
                                            </p>
                                          </div>
                                          <div className="flex items-center w-[30%]">
                                            <p className="bg-[#6750A4] text-white p-2 rounded-l-mdw-[50%]">
                                              Reported Value
                                            </p>
                                            <p className="bg-gray-300 p-2 rounded-r-md w-[50%]">
                                              {report.reportedValue}
                                            </p>
                                          </div>
                                          <div className="flex items-center w-[30%]">
                                            <p className="bg-[#6750A4] text-white p-2 rounded-l-md w-[50%]">
                                              Status
                                            </p>
                                            <p
                                              className={`p-2 rounded-r-md w-[50%] text-white ${className}`}
                                            >
                                              {statusText}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <p>No reports available.</p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrintView;
