import React, { useState, useEffect } from "react";
import { List, Box, Button, TextField, MenuItem } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_SPRINT,
  GET_SPRINT,
  CREATE_SUCCESSFUL_NUDGE,
} from "../../Graphql/User/Query";
import LoadingIndicator from "../Common/LoadingIndicator";
import ToastAlert, { showToast } from "../Common/ToastAlert";
import {
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  parseISO,
  format,
} from "date-fns";
import Plot from "react-plotly.js";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import ShareIcon from "@mui/icons-material/Share";

const SprintCalendar = ({ sprintData, open }) => {
  const [expandedTask, setExpandedTask] = useState(null); // Track expanded task for reporting
  const [reportedValues, setReportedValues] = useState({}); // Current report input values per task
  const [missingReports, setMissingReports] = useState({}); // Missing report inputs: { taskId: { date: value } }
  const [localSprintData, setLocalSprintData] = useState(sprintData);
  const [filterType, setFilterType] = useState("All");

  // Fetch latest sprint data
  const { data, refetch } = useQuery(GET_SPRINT, {
    variables: { sprintId: sprintData.id },
    onCompleted: (fetchedData) => {
      setLocalSprintData(fetchedData.getSprint);
    },
    skip: !sprintData.id,
  });

  const [createSuccessfulNudge, { loading: creatingNudge }] = useMutation(
    CREATE_SUCCESSFUL_NUDGE,
    {
      onCompleted: () => {
        showToast("Nudge shared successfully!", "success");
      },
      onError: (error) => {
        console.error("Error creating successful nudge:", error);
        showToast("Failed to share the nudge.", "error");
      },
    }
  );

  const [updateSprint, { loading: updatingSprint }] = useMutation(
    UPDATE_SPRINT,
    {
      onCompleted: (updatedData) => {
        showToast("Sprint updated successfully", "success");
        setLocalSprintData(updatedData.updateSprint);
      },
      onError: (error) => {
        console.error("Error updating sprint:", error);
        showToast("Error updating sprint", "error");
      },
    }
  );

  useEffect(() => {
    if (sprintData.id) {
      refetch({ sprintId: sprintData.id });
    }
  }, [sprintData.id, refetch]);

  // Toggle expanded view for a task (for reporting)
  const toggleTaskDetails = (taskId) => {
    setExpandedTask((prev) => (prev === taskId ? null : taskId));
  };

  // Update current report input value for a given task
  const handleReportValueChange = (taskId, value) => {
    setReportedValues((prev) => ({
      ...prev,
      [taskId]: value,
    }));
  };

  // Compute missing report dates for a task (daily tasks only)
  const getMissingDates = (task) => {
    if (!localSprintData.startDate) return [];
    const start = parseISO(localSprintData.startDate);
    const today = new Date();
    const totalDays = differenceInDays(today, start) + 1;
    const allDates = Array.from({ length: totalDays }, (_, i) =>
      format(new Date(start.getTime() + i * 86400000), "yyyy-MM-dd")
    );
    const reportedDates = (task.report || []).map((r) =>
      format(parseISO(r.reportedDate), "yyyy-MM-dd")
    );
    return allDates.filter(
      (date) => !reportedDates.includes(date) && date <= format(today, "yyyy-MM-dd")
    );
  };

  // Save a missing report entry for a task on a given date
  const handleSaveMissingReport = (task, date) => {
    const value =
      missingReports[task.id] && missingReports[task.id][date];
    if (value === undefined || value === "") {
      showToast(`Please enter a value for ${date}`, "error");
      return;
    }
    const newReportEntry = {
      reportedDate: new Date(date).toISOString(),
      reportedValue:
        task.variableType === "Continuous"
          ? parseFloat(value)
          : parseInt(value),
      status: "Reported", // temporary; will be recalculated below
    };

    let taskStatus;
    if (task.variableType === "Continuous") {
      const currentCumulativeTotal =
        task.baseline +
        (task.report ? task.report.reduce((sum, r) => sum + r.reportedValue, 0) : 0) +
        newReportEntry.reportedValue;
      if (task.baseline > task.termination) {
        taskStatus =
          currentCumulativeTotal <= task.termination
            ? "Termination"
            : currentCumulativeTotal >= task.goal
            ? "Success"
            : "In Tolerence";
      } else {
        taskStatus = "In Tolerence";
      }
    } else {
      // For dichotomous or categorical tasks, count successes based on the option selected
      const allReports = [...(task.report || []), newReportEntry];
      let totalSuccess = 0;
      if (task.variableType === "Dichotomous") {
        totalSuccess = allReports.filter((r) => r.reportedValue === 1).length;
      } else if (task.variableType === "Likert Scale") {
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        totalSuccess = allReports.filter((r) => r.reportedValue > range / 2).length;
      }
      const percentSuccess = (totalSuccess / allReports.length) * 100;
      taskStatus =
        percentSuccess >= task.goal
          ? "Success"
          : percentSuccess <= task.termination
          ? "Termination"
          : "In Tolerence";
    }

    const updatedTask = {
      ...task,
      report: [...(task.report || []), newReportEntry],
      status: taskStatus,
    };
    const updatedTasks = localSprintData.tasks.map((t) =>
      t.id === task.id ? updatedTask : t
    );
    const updatedSprintData = { ...localSprintData, tasks: updatedTasks };

    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [
        { query: GET_SPRINT, variables: { sprintId: localSprintData.id } },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setLocalSprintData(updatedSprintData);
        // Clear the missing report input for this date
        setMissingReports((prev) => {
          const newMissing = { ...prev };
          if (newMissing[task.id]) {
            delete newMissing[task.id][date];
          }
          return newMissing;
        });
        showToast("Missing report saved successfully", "success");
      },
      onError: (error) => console.error("Error updating sprint:", error),
    });
  };

  // Render a Plotly chart for the task
  const renderChart = (task) => {
    if (!task.report || task.report.length === 0) return null;
    if (task.variableType === "Continuous") {
      // For continuous tasks, show a line graph of cumulative value
      const sortedReports = [...task.report].sort(
        (a, b) => new Date(a.reportedDate) - new Date(b.reportedDate)
      );
      let cumulative = task.baseline;
      const x = [];
      const y = [];
      sortedReports.forEach((report) => {
        cumulative += report.reportedValue;
        x.push(format(parseISO(report.reportedDate), "yyyy-MM-dd"));
        y.push(cumulative);
      });
      return (
        <Plot
          data={[
            {
              x: x,
              y: y,
              type: "scatter",
              mode: "lines+markers",
              marker: { color: "blue" },
            },
          ]}
          layout={{
            title: "Continuous Task Progress",
            autosize: true,
            height: 300,
          }}
          style={{ width: "100%" }}
        />
      );
    } else if (
      task.variableType === "Dichotomous" ||
      task.variableType === "Likert Scale"
    ) {
      // For dichotomous/categorical tasks, show a pie chart (success vs termination)
      let successCount = 0;
      let terminationCount = 0;
      task.report.forEach((report) => {
        if (task.variableType === "Dichotomous") {
          if (report.reportedValue === 1) successCount++;
          else terminationCount++;
        } else if (task.variableType === "Likert Scale") {
          const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
          if (report.reportedValue > range / 2) successCount++;
          else terminationCount++;
        }
      });
      return (
        <Plot
          data={[
            {
              values: [successCount, terminationCount],
              labels: ["Success", "Termination"],
              type: "pie",
            },
          ]}
          layout={{
            title: `${task.variableType} Task Distribution`,
            autosize: true,
            height: 300,
          }}
          style={{ width: "100%" }}
        />
      );
    }
    return null;
  };

  // Handle sharing a successful nudge and update sprint accordingly
  const handleSuccessfulNudges = async () => {
    try {
      const { data } = await createSuccessfulNudge({
        variables: {
          data: {
            sprintData: localSprintData,
            summary: "",
          },
        },
      });
      if (data) {
        const updatedSprintData = { ...localSprintData, sentToSuccess: true };
        await updateSprint({
          variables: { sprintId: localSprintData.id, data: updatedSprintData },
        });
        refetch();
      }
    } catch (error) {
      console.error(
        "Error while sharing successful nudge or updating sprint:",
        error
      );
    }
  };

  // Handle reporting a new value (via main input) for a task
  const handleUpdateTaskReport = (task) => {
    const reportedValue = parseFloat(reportedValues[task.id] || "0");
    let reportStatus = "In Tolerence";
    const now = new Date();
    if (task.variableType === "Dichotomous") {
      if (reportedValue !== 0 && reportedValue !== 1) {
        showToast("Dichotomous value must be 0 or 1", "error");
        return;
      }
      reportStatus = reportedValue === 1 ? "Success" : "Termination";
    } else if (task.variableType === "Likert Scale") {
      const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
      if (reportedValue < 1 || reportedValue > range) {
        showToast(`Categorical value must be between 1 and ${range}`, "error");
        return;
      }
      const mid = range / 2;
      reportStatus = reportedValue > mid ? "Success" : "Termination";
    } else if (task.variableType === "Time Interval") {
      if (reportedValue < 0) {
        showToast("Time Interval value must be 0 or greater", "error");
        return;
      }
      reportStatus = reportedValue >= task.goal ? "Success" : "Termination";
    } else if (task.variableType === "Continuous") {
      if (reportedValue > task.goal - task.baseline) {
        showToast(
          `Maximum allowable value is ${task.goal - task.baseline}`,
          "error"
        );
        return;
      }
      if (reportedValue < task.termination - task.baseline) {
        showToast(
          `Minimum allowable value is ${task.termination - task.baseline}`,
          "error"
        );
        return;
      }
      const startDateParsed = localSprintData.startDate
        ? parseISO(localSprintData.startDate)
        : null;
      const daysElapsed = startDateParsed ? differenceInDays(now, startDateParsed) : 0;
      const cumulativeBaseline = task.baseline + task.successValue * daysElapsed;
      const cumulativeTermination = task.baseline - task.terminationValue * daysElapsed;
      reportStatus =
        reportedValue >= cumulativeBaseline
          ? "Success"
          : reportedValue <= cumulativeTermination
          ? "Termination"
          : "In Tolerence";
    }
    const newReportEntry = {
      reportedDate: now.toISOString(),
      reportedValue,
      status: reportStatus,
    };

    let taskStatus;
    if (task.variableType === "Continuous") {
      const currentCumulativeTotal =
        task.baseline +
        (task.report ? task.report.reduce((sum, r) => sum + r.reportedValue, 0) : 0) +
        reportedValue;
      if (currentCumulativeTotal >= task.goal) {
        taskStatus = "Success";
      } else if (currentCumulativeTotal <= task.termination) {
        taskStatus = "Termination";
      } else {
        taskStatus = "In Tolerence";
      }
    } else {
      const allReports = [...(task.report || []), newReportEntry];
      let totalSuccess = 0;
      if (task.variableType === "Dichotomous") {
        totalSuccess = allReports.filter((r) => r.reportedValue === 1).length;
      } else if (task.variableType === "Likert Scale") {
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        totalSuccess = allReports.filter((r) => r.reportedValue > range / 2).length;
      }
      const percent = (totalSuccess / allReports.length) * 100;
      taskStatus =
        percent >= task.goal
          ? "Success"
          : percent <= task.termination
          ? "Termination"
          : "In Tolerence";
    }
    const updatedTask = {
      ...task,
      report: [...(task.report || []), newReportEntry],
      status: taskStatus,
    };
    const updatedTasks = localSprintData.tasks.map((t) =>
      t.id === task.id ? updatedTask : t
    );
    const updatedSprintData = { ...localSprintData, tasks: updatedTasks };
    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [
        { query: GET_SPRINT, variables: { sprintId: localSprintData.id } },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setLocalSprintData(updatedSprintData);
        setReportedValues((prev) => ({ ...prev, [task.id]: "" }));
        setExpandedTask(null);
        showToast("Value Reported Successfully", "success");
      },
      onError: (error) => console.error("Error updating sprint:", error),
    });
  };

  // Render reported value (for dichotomous tasks, display the selected label)
  const renderReportedValue = (task, reportedValue) => {
    const dichotomousOptions = [
      "",
      "Yes / No",
      "True / False",
      "Male / Female",
      "Greater / Lesser",
      "0 / 1",
    ];
    const selectedOption = dichotomousOptions[task.baseline] || "";
    return task.variableType === "Dichotomous"
      ? reportedValue === 1
        ? selectedOption.split(" / ")[0]
        : selectedOption.split(" / ")[1]
      : reportedValue;
  };

  const renderStatusDropdown = (task) => {
    return (
      <div className="flex flex-row w-[100%]">
        <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
          Status
        </h1>
        <select
          value={task.status || "In Tolerence"}
          onChange={(e) => handleStatusChange(task.id, e.target.value)}
          className="bg-white border-[1px] border-gray-400 rounded p-2 h-[42px] w-[50%]"
        >
          <option value="Success">Success</option>
          <option value="Termination">Termination</option>
          <option value="In Tolerence">In Tolerence</option>
        </select>
      </div>
    );
  };

  const renderInstructions = (task) => {
    let instructionText = "";
    let calculatedRange = 0;
    const dichotomousOptions = [
      "",
      "Yes / No",
      "True / False",
      "Male / Female",
      "Greater / Lesser",
      "0 / 1",
    ];
    const selectedOption = dichotomousOptions[task.baseline];
    switch (task.variableType) {
      case "Dichotomous":
        instructionText = `Report the value as "${selectedOption}", where the ${selectedOption.split("/")[0]} represents success and the ${selectedOption.split("/")[1]} represents termination.`;
        break;
      case "Likert Scale":
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        calculatedRange = range / 2;
        instructionText = `Report a value between 1 and ${range}. Values greater than ${calculatedRange} indicate success, while values below ${calculatedRange} indicate failure.`;
        break;
      case "Continuous":
        const now = new Date();
        let daysElapsed = 0;
        if (task.frequency === "Hour") {
          daysElapsed = localSprintData.startDate
            ? differenceInHours(now, parseISO(localSprintData.startDate))
            : 0;
        } else if (task.frequency === "Day") {
          daysElapsed = localSprintData.startDate
            ? differenceInDays(now, parseISO(localSprintData.startDate))
            : 0;
        } else if (task.frequency === "Week") {
          daysElapsed = localSprintData.startDate
            ? differenceInWeeks(now, parseISO(localSprintData.startDate))
            : 0;
        }
        const cumulativeBaseline =
          task.baseline + task.successValue * daysElapsed;
        const cumulativeTermination =
          task.baseline - task.terminationValue * daysElapsed;
        const currentCumulativeTotal =
          task.baseline +
          (task.report ? task.report.reduce((sum, report) => sum + report.reportedValue, 0) : 0);
        const getStatusMessage = (
          value,
          task,
          cumulativeBaseline,
          cumulativeTermination
        ) => {
          if (value >= task.goal) {
            return "You have achieved the goal. Please set the task to Success.";
          } else if (value <= task.termination) {
            return "You are below the termination value. Terminate immediately.";
          } else if (value < cumulativeTermination) {
            return "You are below the threshold. Improve immediately.";
          } else if (value > cumulativeBaseline) {
            return "You are on track. Keep up the good work!";
          } else {
            return "You are in tolerence but can improve.";
          }
        };
        const statusMessage = getStatusMessage(
          currentCumulativeTotal,
          task,
          cumulativeBaseline,
          cumulativeTermination
        );
        instructionText = `
          <table style="width: 100%; border-collapse: collapse; color: black">
            <thead>
              <tr>
                <th style="border: 1px solid #ccc; padding: 8px;">Metric</th>
                <th style="border: 1px solid #ccc; padding: 8px;">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="border: 1px solid #ccc; padding: 8px;">Current Cumulative Value</td>
                <td style="border: 1px solid #ccc; padding: 8px;">${currentCumulativeTotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; padding: 8px;">Expected Value (as of today)</td>
                <td style="border: 1px solid #ccc; padding: 8px;">${cumulativeBaseline.toFixed(2)}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; padding: 8px;">Termination Threshold (as of today)</td>
                <td style="border: 1px solid #ccc; padding: 8px;">${cumulativeTermination.toFixed(2)}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; padding: 8px;">Current Status</td>
                <td style="border: 1px solid #ccc; padding: 8px;">${statusMessage}</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; padding: 8px;">Instructions</td>
                <td style="border: 1px solid #ccc; padding: 8px;">
                  Report a positive or negative change observed. If your cumulative value increases, report the change.
                </td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; padding: 8px;">Example</td>
                <td style="border: 1px solid #ccc; padding: 8px;">
                  If your cumulative value is 100 and increased by 10, report 10.
                </td>
              </tr>
            </tbody>
          </table>
        `;
        break;
      case "Time Interval":
        instructionText =
          "Report the value at this specific instance, representing the time interval being measured.";
        break;
      default:
        instructionText =
          "Please select a variable type for detailed instructions.";
    }
    return (
      <p
        className="text-gray-500 text-sm pb-3"
        dangerouslySetInnerHTML={{ __html: instructionText }}
      />
    );
  };

  const handleStatusChange = (taskId, newStatus) => {
    const updatedTasks = localSprintData.tasks.map((task) =>
      task.id === taskId ? { ...task, status: newStatus } : task
    );
    const updatedSprintData = { ...localSprintData, tasks: updatedTasks };
    setLocalSprintData(updatedSprintData);
    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [{ query: GET_SPRINT, variables: { sprintId: localSprintData.id } }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        showToast("Task status updated successfully", "success");
      },
      onError: (error) => {
        console.error("Error updating task status:", error);
        showToast("Error updating task status", "error");
      },
    });
  };

  if (updatingSprint) return <LoadingIndicator />;

  const handleSprintStatusChange = (newStatus) => {
    const updatedSprintData = { ...localSprintData, status: newStatus };
    setLocalSprintData(updatedSprintData);
    updateSprint({
      variables: { sprintId: localSprintData.id, data: updatedSprintData },
      refetchQueries: [{ query: GET_SPRINT, variables: { sprintId: localSprintData.id } }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        showToast("Sprint status updated successfully", "success");
      },
      onError: (error) => {
        console.error("Error updating sprint status:", error);
        showToast("Error updating sprint status", "error");
      },
    });
  };

  // Filter tasks by variable type
  const filteredTasks = localSprintData.tasks.filter((task) => {
    if (filterType === "All") return true;
    if (filterType === "Categorical") return task.variableType === "Likert Scale";
    return task.variableType === filterType;
  });

  // Render current value input for reporting (dropdown for dichotomous/categorical; numeric for continuous)
  const renderCurrentValueField = (task) => {
    const dichotomousOptions = [
      { label: "Yes / No", values: { termination: "No", success: "Yes" } },
      { label: "True / False", values: { termination: "False", success: "True" } },
      { label: "Male / Female", values: { termination: "Female", success: "Male" } },
      { label: "Greater / Lesser", values: { termination: "Lesser", success: "Greater" } },
      { label: "0 / 1", values: { termination: "0", success: "1" } },
    ];
    // For simplicity, use the first option if baseline is not set appropriately
    const selectedOption = dichotomousOptions[task.baseline - 1] || dichotomousOptions[0];
    return task.variableType === "Dichotomous" ? (
      <div className="flex items-center w-[50%]">
        <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[50%] flex justify-center items-center text-center text-black border border-inputsborder">
          Current Value
        </h1>
        <select
          value={
            reportedValues[task.id] === 1
              ? selectedOption.values.success
              : selectedOption.values.termination
          }
          onChange={(e) => {
            const isSuccess = e.target.value === selectedOption.values.success;
            handleReportValueChange(task.id, isSuccess ? 1 : 0);
          }}
          className="bg-white border-[1px] border-gray-400 rounded-r-[5px] text-black p-2 h-[42px] w-[50%]"
        >
          <option value={selectedOption.values.termination}>
            {selectedOption.values.termination}
          </option>
          <option value={selectedOption.values.success}>
            {selectedOption.values.success}
          </option>
        </select>
      </div>
    ) : task.variableType === "Likert Scale" ? (
      (() => {
        const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
        const options = Array.from({ length: range }, (_, i) => i + 1);
        return (
          <div className="flex items-center w-[50%]">
            <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[50%] flex justify-center items-center text-center text-black border border-inputsborder">
              Current Value
            </h1>
            <select
              value={reportedValues[task.id] || ""}
              onChange={(e) => handleReportValueChange(task.id, e.target.value)}
              className="bg-white border-[1px] border-gray-400 rounded-r-[5px] text-black p-2 h-[42px] w-[50%]"
            >
              <option value="">Select Value</option>
              {options.map((opt) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </select>
          </div>
        );
      })()
    ) : (
      <div className="flex items-center w-[50%]">
        <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[50%] flex justify-center items-center text-center text-black border border-inputsborder">
          Current Value
        </h1>
        <input
          placeholder="Reported Value"
          type="number"
          value={reportedValues[task.id] || ""}
          onChange={(e) => handleReportValueChange(task.id, e.target.value)}
          className="bg-white border-[1px] border-gray-400 rounded-r-[5px] text-black p-2 h-[42px] w-[50%]"
        />
      </div>
    );
  };

  // DO NOT show missing values inputs if the sprint has ended (Success or Termination)
  const shouldShowMissingInputs = localSprintData.status === "In Tolerence";
console.log(localSprintData.status)
  return (
    <div className="shadow-md shadow-gray-400 bg-secondary flex flex-col rounded-[15px] w-full h-full font-roboto text-base overflow-scroll scrollbar border-[1px] border-gray-300">
      <ToastAlert />
      <div className="w-full flex justify-end p-2" onClick={handleSuccessfulNudges}>
        {localSprintData.status === "Success" && !localSprintData.sentToSuccess && <ShareIcon />}
      </div>
      <div className="p-4 w-full space-y-4">
        {/* General Sprint Information */}
        <div className="font-medium flex flex-col w-full border-b-[1px] border-gray-300 pb-2">
          <div className="flex flex-row w-full">
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Stakeholder</h1>
              <h1 className="text-sm">{localSprintData.stakeholderName}</h1>
            </div>
            <div className="flex flex-col space-y-1 w-[67%]">
              <h1 className="font-semibold">Target behavior</h1>
              <h1 className="text-sm">{localSprintData.behaviorName}</h1>
            </div>
          </div>
        </div>
        <div className="font-medium flex flex-col w-full border-b-[1px] border-gray-300 pb-2">
          <div className="flex flex-row w-full">
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Nudge Type</h1>
              <h1 className="text-sm">{localSprintData.nudgeName}</h1>
            </div>
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Sprinter Name</h1>
              <h1 className="text-sm">{localSprintData.sprinterName}</h1>
            </div>
            <div className="flex flex-col space-y-1 w-[33%]">
              <h1 className="font-semibold">Sprint Name</h1>
              <h1 className="text-sm">{localSprintData.name}</h1>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-[100%]">
          <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center text-black border border-inputsborder">
            Sprint Name
          </h1>
          <div className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-white">
            {localSprintData.name}
          </div>
        </div>
        <div className="flex flex-row w-full justify-between border-b-[1px] border-gray-400 pb-4">
          <div className="flex flex-row w-[45%]">
            <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-black border border-inputsborder">
              Start Date
            </h1>
            <div className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-white flex justify-center items-center">
              {localSprintData.startDate}
            </div>
          </div>
          <div className="flex flex-row w-[45%]">
            <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-black border border-inputsborder">
              End Date
            </h1>
            <div className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-white flex justify-center items-center">
              {localSprintData.endDate}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-1 w-[70%]">
          <h1 className="font-semibold">Sprint Status</h1>
          <div className="flex items-center space-x-4">
            <label htmlFor="sprint-status" className="font-medium text-gray-700">
              Sprint Status:
            </label>
            <select
              id="sprint-status"
              value={localSprintData.status}
              onChange={(e) => handleSprintStatusChange(e.target.value)}
              className="border border-gray-300 rounded-md p-2 text-gray-700 focus:outline-none focus:ring focus:ring-indigo-500"
            >
              <option value="Success">Success</option>
              <option value="Termination">Termination</option>
              <option value="In Tolerence">In Tolerence</option>
            </select>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <h1 className="font-semibold">Filter by Variable Type:</h1>
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="border border-gray-300 rounded-md p-2 text-gray-700 focus:outline-none focus:ring focus:ring-indigo-500"
          >
            <option value="All">All</option>
            <option value="Dichotomous">Dichotomous</option>
            <option value="Likert Scale">Categorical</option>
            <option value="Continuous">Continuous</option>
            <option value="Time Interval">Time Interval</option>
          </select>
        </div>
        <List>
          <h1 className="font-semibold ml-4">Behavioral Nudges:</h1>
          {filteredTasks.map((task) => (
            <div key={task.id} className="border-b-[1px] border-gray-300">
              <div className="flex justify-between items-center px-4 py-2">
                <div className="w-[75%] min-h-[57px] rounded-[5px] border border-inputsborder p-2 bg-inputssecondary">
                  {task.name}
                </div>
                <button
                  onClick={() => toggleTaskDetails(task.id)}
                  className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium flex items-center justify-center text-base"
                >
                  {expandedTask === task.id ? "Done" : (
                    <div className="flex flex-row justify-center items-center space-x-2">
                      <AddCircleIcon /> <h1>Report</h1>
                    </div>
                  )}
                </button>
              </div>
              {expandedTask === task.id && (
                <Box className="px-4 py-2 space-y-3">
                  <div className="flex flex-row w-[100%]">
                    <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex pl-2 items-center text-black border border-inputsborder">
                      Variable Type
                    </h1>
                    <div className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary">
                      {task.variableType === "Likert Scale" ? "Categorical" : task.variableType}
                    </div>
                  </div>
                  {/* Render additional variable fields based on type */}
                  {(() => {
                    switch (task.variableType) {
                      case "Dichotomous":
                        return (
                          <div className="flex flex-row w-[100%] pb-3">
                            <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                              Dichotomous Options
                            </h1>
                            <select
                              disabled
                              value={task.baseline || ""}
                              className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary"
                            >
                              <option value="" disabled>Select Option</option>
                              <option value={1}>Yes / No</option>
                              <option value={2}>True / False</option>
                              <option value={3}>Male / Female</option>
                              <option value={4}>Greater / Lesser</option>
                              <option value={5}>0 / 1</option>
                            </select>
                          </div>
                        );
                      case "Likert Scale":
                        return (
                          <div className="flex flex-row w-[45%] pb-3">
                            <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                              Categorical
                            </h1>
                            <select
                              disabled
                              value={task.baseline || ""}
                              className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2"
                            >
                              <option value="">Select Scale</option>
                              <option value={1}>1-5</option>
                              <option value={2}>1-7</option>
                              <option value={3}>1-10</option>
                            </select>
                          </div>
                        );
                      case "Continuous":
                        return (
                          <div className="flex flex-col space-y-2 w-[100%]">
                            <div className="flex flex-row w-full justify-between mb-2">
                              <div className="w-[45%] space-y-1">
                                <div className="flex flex-row w-full">
                                  <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                    Baseline
                                  </h1>
                                  <h1
                                 
                                 className="w-[62%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary text-black"
                                 placeholder="Enter termination"
                               >{task.baseline || ""}</h1>
                                </div>
                              </div>
                              <div className="flex flex-row w-[45%]">
                                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                  Goal
                                </h1>
                                <h1
                                 
                                    className="w-[62%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary text-black"
                                    placeholder="Enter termination"
                                  >{task.goal || ""}</h1>
                              </div>
                            </div>
                            <div className="flex flex-row w-full pb-3 justify-between">
                              <div className="flex flex-row w-[45%]">
                                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                  Termination
                                </h1>
                                <div className="w-[62%]">
                                  <h1
                                 
                                    className="w-[100%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary text-black"
                                    placeholder="Enter termination"
                                  >{task.termination || ""}</h1>
                                  <h1 className="text-sm text-gray-500 text-end">
                                    Enter -1 for 0
                                  </h1>
                                </div>
                              </div>
                              <div className="flex flex-row w-[45%]">
                                <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                  Frequency
                                </h1>
                                <select
                                  disabled
                                  value={task.frequency || ""}
                                  className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary"
                                >
                                  <option value="">Select Frequency </option>
                                  <option value="Day">Daily</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })()}
                  {renderStatusDropdown(task)}
                  <h1 className="text-gray-400 text-[18px]">
                    Question: {task.variableDescription} ?
                  </h1>
                  {renderInstructions(task)}
                  {renderChart(task)}
                  {/* Missing date reporting: Only show if sprint is active */}
                  {shouldShowMissingInputs && (
                    <div className="mt-4">
                      <h1 className="font-semibold text-red-600">Missing Reported Dates:</h1>
                      {getMissingDates(task).length === 0 ? (
                        <p className="text-green-600">All dates reported</p>
                      ) : (
                        getMissingDates(task).map((date) => (
                          <div key={date} className="flex flex-row items-center space-x-2 my-1">
                            <div className="w-[40%] bg-red-100 p-2 rounded">{date}</div>
                            {task.variableType === "Dichotomous" ? (
                              // For dichotomous, display dropdown matching current value options
                              (() => {
                                const options = [
                                  { value: "0", label: "No" },
                                  { value: "1", label: "Yes" },
                                ];
                                return (
                                  <select
                                    value={
                                      (missingReports[task.id] && missingReports[task.id][date]) || ""
                                    }
                                    onChange={(e) =>
                                      setMissingReports((prev) => ({
                                        ...prev,
                                        [task.id]: {
                                          ...(prev[task.id] || {}),
                                          [date]: e.target.value,
                                        },
                                      }))
                                    }
                                    className="bg-white border-[1px] border-gray-400 rounded text-black p-2 h-[42px] w-[50%]"
                                  >
                                    <option value="">Select Value</option>
                                    {options.map((opt) => (
                                      <option key={opt.value} value={opt.value}>
                                        {opt.label}
                                      </option>
                                    ))}
                                  </select>
                                );
                              })()
                            ) : task.variableType === "Likert Scale" ? (
                              // For categorical, display dropdown with scale options
                              (() => {
                                const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
                                const options = Array.from({ length: range }, (_, i) => i + 1);
                                return (
                                  <select
                                    value={
                                      (missingReports[task.id] && missingReports[task.id][date]) || ""
                                    }
                                    onChange={(e) =>
                                      setMissingReports((prev) => ({
                                        ...prev,
                                        [task.id]: {
                                          ...(prev[task.id] || {}),
                                          [date]: e.target.value,
                                        },
                                      }))
                                    }
                                    className="bg-white border-[1px] border-gray-400 rounded text-black p-2 h-[42px] w-[50%]"
                                  >
                                    <option value="">Select Value</option>
                                    {options.map((opt) => (
                                      <option key={opt} value={opt}>
                                        {opt}
                                      </option>
                                    ))}
                                  </select>
                                );
                              })()
                            ) : (
                              // For continuous tasks, show numeric input
                              <input
                                type="number"
                                placeholder="Report value"
                                value={
                                  (missingReports[task.id] && missingReports[task.id][date]) || ""
                                }
                                onChange={(e) =>
                                  setMissingReports((prev) => ({
                                    ...prev,
                                    [task.id]: {
                                      ...(prev[task.id] || {}),
                                      [date]: e.target.value,
                                    },
                                  }))
                                }
                                className="border border-gray-400 p-2 rounded h-[42px] w-[50%]"
                              />
                            )}
                            <button
                              onClick={() => handleSaveMissingReport(task, date)}
                              className="bg-bcolor rounded-full px-4 py-2 text-white"
                            >
                              Save
                            </button>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                  {new Date(localSprintData.endDate) < Date.now() ? (
                    <h1 className="font-semibold">Your Sprint Has Ended</h1>
                  ) : task.variableType === "Continuous" &&
                    task.status === "Success" ? (
                    <h1 className="font-semibold">
                      You have reached success. No further reporting needed. Congrats!!!
                    </h1>
                  ) : (
                    <div className="flex items-center w-full">
                      {(task.variableType !== "Dichotomous" &&
                        task.variableType !== "Likert Scale") && (
                        <div className="flex items-center w-[50%]">
                          <h1 className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[50%] flex justify-center items-center text-center text-black border border-inputsborder">
                            Current Value
                          </h1>
                          <input
                            placeholder="Reported Value"
                            type="number"
                            value={reportedValues[task.id] || ""}
                            onChange={(e) =>
                              handleReportValueChange(task.id, e.target.value)
                            }
                            className="bg-white border-[1px] border-gray-400 rounded-r-[5px] text-black p-2 h-[42px] w-[50%]"
                          />
                        </div>
                      )}
                      {(task.variableType === "Dichotomous" ||
                        task.variableType === "Likert Scale") && (
                        <div className="flex items-center w-[50%]">
                          {renderCurrentValueField(task)}
                        </div>
                      )}
                      <button
                        className="bg-bcolor rounded-full px-6 py-2.5 text-white font-medium ml-3"
                        onClick={() => handleUpdateTaskReport(task)}
                      >
                        Report Value
                      </button>
                    </div>
                  )}
                  {task.report && task.report.length > 0 && (
                    <div className="mt-3">
                      {task.report.map((report, index) => {
                        const { className, statusText } = (function calculateReportStatus(
                          reportedValue,
                          task,
                          index
                        ) {
                          if (task.variableType === "Continuous") {
                            const now = new Date();
                            const daysElapsed = localSprintData.startDate
                              ? differenceInDays(now, parseISO(localSprintData.startDate))
                              : 0;
                            const cumulativeBaseline =
                              task.baseline + task.successValue * daysElapsed;
                            const cumulativeTermination =
                              task.baseline - task.terminationValue * daysElapsed;
                            const previousTotal =
                              (task.report || [])
                                .slice(0, index)
                                .reduce((sum, r) => sum + r.reportedValue, 0) +
                              task.baseline;
                            const cumulativeReported =
                              previousTotal + report.reportedValue;
                            if (cumulativeReported >= cumulativeBaseline) {
                              return { className: "bg-[#8BC34A]", statusText: "Success" };
                            } else if (cumulativeReported <= cumulativeTermination) {
                              return { className: "bg-[#B3261E]", statusText: "Termination" };
                            } else {
                              return { className: "bg-[#FFBD2E]", statusText: "In Tolerence" };
                            }
                          } else if (task.variableType === "Dichotomous") {
                            return report.reportedValue === 1
                              ? { className: "bg-[#8BC34A]", statusText: "Success" }
                              : { className: "bg-[#B3261E]", statusText: "Termination" };
                          } else if (task.variableType === "Likert Scale") {
                            const range = task.baseline === 1 ? 5 : task.baseline === 2 ? 7 : 10;
                            const mid = range / 2;
                            return report.reportedValue > mid
                              ? { className: "bg-[#8BC34A]", statusText: "Success" }
                              : { className: "bg-[#B3261E]", statusText: "Termination" };
                          } else if (task.variableType === "Time Interval") {
                            return report.reportedValue >= task.goal
                              ? { className: "bg-[#8BC34A]", statusText: "Success" }
                              : { className: "bg-[#B3261E]", statusText: "Termination" };
                          }
                          return { className: "bg-[#FFBD2E]", statusText: "In Tolerence" };
                        })(report.reportedValue, task, index);
                        return (
                          <div
                            key={index}
                            className="flex flex-row mb-2 justify-between w-full"
                          >
                            <div className="flex items-center w-[30%]">
                              <div className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                Date
                              </div>
                              <div className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary">
                                {open
                                  ? report.reportedDate.substring(2, 10)
                                  : report.reportedDate.substring(0, 10)}
                              </div>
                            </div>
                            <div className="flex items-center w-[30%]">
                              <div className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                Value
                              </div>
                              <div className="w-[60%] h-[42px] rounded-r-[5px] border border-inputsborder p-2 bg-inputssecondary">
                                {renderReportedValue(task, report.reportedValue)}
                              </div>
                            </div>
                            <div className="flex items-center w-[30%]">
                              <div className="font-medium bg-inputsprimary h-[42px] rounded-l-[5px] w-[40%] flex justify-center items-center text-center text-black border border-inputsborder">
                                Status
                              </div>
                              <div
                                className={`flex justify-center items-center p-2 rounded-r-[5px] w-[60%] ${className} border-[1px] border-gray-400 h-[42px]`}
                              >
                                {statusText}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Box>
              )}
            </div>
          ))}
        </List>
      </div>
    </div>
  );
};

export default SprintCalendar;
