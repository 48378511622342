import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./Pages/Landing";
import Loggedin from "./Pages/Loggedin";

import CustomBuilder from "./Components/AgileBuilder/CustomBuilder";
import CreateProblem from "./Components/AgileBuilder/CreateProblem";

import LoadingIndicator from "./Components/Common/LoadingIndicator";
import SprintCalendar from "./Components/AgileBuilder/SprintCalendar";
import Tutorial from "./Components/AgileBuilder/Tutorial";
import DemandIndex from "./Components/AgileBuilder/DemandIndex";

import ProfilePage from "./Components/AgileBuilder/ProfilePage";
import Admin from "./Components/AgileBuilder/Admin";

import LandingVideo from "./Components/AgileBuilder/LandingVideo";
import PrintView from "./Components/AgileBuilder/PrintView";

import MySprints from "./Components/AgileBuilder/MySprints";
import TutorialHowTo from "./Components/AgileBuilder/TutorialHowTo";
import TutorialResources from "./Components/AgileBuilder/TutorialResources";
import TutorialSucessfulNudges from "./Components/AgileBuilder/TutorialSucessfulNudges";
import Aboutus from "./Components/AgileBuilder/Aboutus";
import CreateOrganization from "./Components/AgileBuilder/CreateOrganization";
// Lazy-loaded components
const Login = lazy(() => import("./Pages/Login"));

const ForgotPassword = lazy(() => import("./Pages/ForgotPassword"));

function App() {
  return (
    <div className="xs:w-xs sm:w-sm md:w-md lg:w-screen bg-primary">
      <section>
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/landingvideos"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <LandingVideo />
              </Suspense>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <Suspense fallback={<LoadingIndicator />}>
                <ForgotPassword />
              </Suspense>
            }
          />

          <Route path="/signup" element={<Landing />} />
          <Route path="/" element={<Loggedin />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <CreateProblem />
                </Suspense>
              }
            />

            <Route
              path="/nudgebuilder/0"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <CreateProblem />
                </Suspense>
              }
            />

            <Route
              path="/admin"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Admin />
                </Suspense>
              }
            />
            <Route
              path="/organizations"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <CreateOrganization />
                </Suspense>
              }
            />

            <Route
              path="/demandindex"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <DemandIndex />
                </Suspense>
              }
            />
            <Route
              path="/nudgebuilder/custombuilder"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <CustomBuilder />
                </Suspense>
              }
            />
            <Route
              path="/print"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <PrintView />
                </Suspense>
              }
            />
            <Route
              path="/aboutus"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Aboutus />
                </Suspense>
              }
            />
          

            <Route
              path="/tutorial"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <Tutorial />
                </Suspense>
              }
            />
            <Route
              path="/tutorial/howto"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <TutorialHowTo />
                </Suspense>
              }
            />
            <Route
              path="/tutorial/resources"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <TutorialResources />
                </Suspense>
              }
            />
            <Route
              path="/tutorial/sucessfulnudges"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <TutorialSucessfulNudges />
                </Suspense>
              }
            />
            <Route
              path="/mysprints"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <MySprints />
                </Suspense>
              }
            />
            <Route
              path="/sprintmonitor/0"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <SprintCalendar />
                </Suspense>
              }
            />

            <Route
              path="/profile"
              element={
                <Suspense fallback={<LoadingIndicator />}>
                  <ProfilePage />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </section>
    </div>
  );
}

export default App;
